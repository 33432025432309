import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { Contactanos } from 'src/app/header/_models/contactanos';
import { DataMenu } from 'src/app/header/_models/data-menu';
import { LogoPlataformaComponent } from 'src/app/header/utils/logo-plataforma/logo-plataforma.component';
import { TagNewComponent } from 'src/app/header/utils/tag-new/tag-new.component';


@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    LogoPlataformaComponent,
    TagNewComponent
  ]
})


export class MenuMobileComponent implements OnInit {
  plataforma: string;
  domainSegoUrl: string;
  returnUrlParam: string;
  datosContacto: Contactanos[];

  constructor(
    public dialogRef: MatDialogRef<MenuMobileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataMenu,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _usuarioService: UsuarioService,
    private _platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.domainSegoUrl = this._platformService.getSubdominioUrl('segofinance');
    this.getReturnUrl();
    this.getContactoLinks();
  }

  
  private getReturnUrl(): void {
    // Comprobamos que la ruta no venga del registro/login para asignar el returnUrl correcto
    const currentUrl = this._router.url.split('?')[0];
    if (currentUrl === '/login' || currentUrl === '/registro') {
      const params = this._activatedRoute.snapshot.queryParamMap;
      if (params.has('returnUrl')) {
        this.returnUrlParam = params.get('returnUrl');
      } else {
        this.returnUrlParam = currentUrl;
      }
    } else {
      this.returnUrlParam = currentUrl.split('?')[0];
    }
    this.getPlataforma();
  }

  private getPlataforma(): void {
    this.plataforma = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment].toLowerCase();
  }

  private getContactoLinks(): void{
    this.datosContacto = [
      {
        href: "https://outlook.office365.com/book/SEGOInversores@segofinance.com/",
        icon: "today",
        title: "Llamar al Departamento de Inversores"
      },
      {
        href: "mailto:info@segofinance.com",
        icon: "email",
        title: "Enviar email"
      }
    ]
  }

  openPopupCnmv(plataformaDestino: string, url: string){
    if(this.plataforma === plataformaDestino || 
      (this.plataforma === 'venture' && (plataformaDestino === 'realestate' || plataformaDestino === 'creative')) || 
      (this.plataforma === 'realestate' && (plataformaDestino === 'venture' || plataformaDestino === 'creative')) ||
      (this.plataforma === 'creative' && (plataformaDestino === 'venture' || plataformaDestino === 'realestate')))
    {
      this._router.navigateByUrl(url);
    } else {
      this._platformService.openAvisoSalidaCnmv(this.plataforma, plataformaDestino, url);
    }
  }

  logOut(): void
  {
    this._usuarioService.logOut(true);
  }
}

