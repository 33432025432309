import { Component } from '@angular/core';
import { SharedModule } from 'src/app/_shared/_shared.module';

@Component({
  selector: 'app-cabecera-blast',
  templateUrl: './cabecera-blast.component.html',
  styleUrls: ['./cabecera-blast.component.scss'],
  standalone: true,
  imports:[
    SharedModule
  ]
})
export class CabeceraBlastComponent {

}
