import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UsuarioService } from '../_services/usuario.service';
import { isPlatformBrowser } from "@angular/common";
import { environment } from 'src/environments/environment';


@Injectable()
export class JwtInterceptor implements HttpInterceptor
{
    url: string;

    constructor(
        @Inject(PLATFORM_ID) private _platformBrowserId,
        private _usuarioService: UsuarioService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (isPlatformBrowser(this._platformBrowserId))
        {
            let isAuthenticated = this._usuarioService.isAuthenticated();
            // request que se envía a la api
            let requestURL = request.url;
            // routa actual del navegador
            let isInRoute = this.includedUrls.some(url => window.location.pathname.includes(url));

            if ((isInRoute || requestURL.includes("wallet")) && !isAuthenticated)
            {
                this._usuarioService.logOut(true)
            }

            request = request.clone({ headers: request.headers.set("Ocp-Apim-Subscription-Key", environment.apiKey) });

            let sessionData = this._usuarioService.getSession();

            if (sessionData)
            {
                request = request.clone({ headers: request.headers.set("Authorization", "Bearer " + sessionData.token) });
            }

            return next.handle(request).pipe(catchError((error, caught) =>
            {
                if (error.status === 401 || error.status === 403)
                {
                    this.url = window.location.href;
                    var redirect = false;
                    if (this.url.includes("blast"))
                    {
                        redirect = true;
                    }
                    if (!this.url.includes("login"))
                    {
                        this._usuarioService.logOut(redirect);
                    }
                    else
                    {
                        this._usuarioService.logOut();
                    }
                }
                return throwError(error);
            }))
        }
        return next.handle(request);
    }

    // indicar todas las llamadas que deberían ser excluidas en la verificación de token
    private includedUrls = ["panel-inversor"];

}
