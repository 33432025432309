<div id="menu-mobile" class="menu-mobile-height">
  <!-- Cabecera menu -->
  @if (plataforma) {
    <nav
      class="navbar navbar-segofinance menu-mobile-header align-items-center shadow-8 ps-3 pe-2 menu-close fixed-top">
      <app-logo-plataforma [plataforma]="plataforma" [mat-dialog-close]="true"></app-logo-plataforma>
      <span class="material-symbols-outlined icon-close text-end" role="button" [mat-dialog-close]="true"
      title="Cerrar">close</span>
    </nav>
  }

  <!-- Content menu-->
  @if (data && data.secciones) {
    <section
      class="margin-header d-flex flex-column align-content-between h-100 px-3 fadeinMenu">
      <!-- Acceso / Registro -->
      <div class="row">
        @if (!data.isLogged) {
          <div class="col-6 my-3 menu-preitem">
            <a class="btn btn-primary btn-menu-mb border-2 d-flex align-items-center justify-content-center"
              routerLink="/login" [queryParams]="{returnUrl: returnUrlParam}" title="Acceder" role="button"
              [matDialogClose]="true">
              Acceder
            </a>
          </div>
          <div class="col-6 my-3 menu-preitem">
            <a class="btn btn-secondary btn-menu-mb border-2 d-flex align-items-center justify-content-center"
              routerLink="/registro" [queryParams]="{returnUrl: returnUrlParam}" title="Regístrate"
              role="button" [matDialogClose]="true">
              Regístrate
            </a>
          </div>
        }
        @if (data.isLogged) {
          <div class="col-6 my-3 menu-preitem">
            @if (plataforma === 'SEGOFINANCE') {
              <a
                class="btn btn-primary btn-menu-mb border-2 d-flex align-items-center justify-content-center"
                routerLink="/panel-inversor/info-usuario" title="Mi Panel" role="button"
                [matDialogClose]="true">
                Mi panel
              </a>
            }
            @if (plataforma !== 'SEGOFINANCE') {
              <a
                class="btn btn-primary btn-menu-mb border-2 d-flex align-items-center justify-content-center"
                href="{{domainSegoUrl + '/panel-inversor/info-usuario'}}" title="Mi Panel" role="button"
                [matDialogClose]="true">
                Mi panel
              </a>
            }
          </div>
          <div class="col-6 my-3 menu-preitem">
            <a class="btn btn-secondary btn-menu-mb border-2 d-flex align-items-center justify-content-center"
              title="Salir" role="button" (click)="logOut()" [matDialogClose]="true">
              Salir
            </a>
          </div>
        }
        <div class="col-12">
          <div class="linea-separadora-1 border-primary-100 mb-2"></div>
        </div>
      </div>
      <!-- Secciones Accordions -->
      <div class="row">
        @for (seccion of data.secciones; track seccion) {
          <div class="col-12">
            @if (!seccion.subsecciones) {
              <div class="row">
                <div class="col-12">
                  <a routerLink="{{seccion.route}}" title="{{seccion.nombre}}"
                    class="menu-item ps-2 d-flex align-items-center w-100"
                    [queryParams]="{'filter': seccion.routeParam}" [matDialogClose]="true">
                    <span
                    class="material-symbols-outlined icon-seccion primary-500 me-2">{{seccion.icon}}</span>
                    <span class="all-menu mb-0 primary-800">{{seccion.nombre}}</span>
                  </a>
                </div>
                <div class="col-12">
                  <div class="linea-separadora-1 border-primary-100 my-2"></div>
                </div>
              </div>
            }
            @if (seccion.subsecciones) {
              <div class="row">
                <div class="col-12">
                  <mat-accordion>
                    <mat-expansion-panel class="menu-accordion mat-elevation-z">
                      <mat-expansion-panel-header class="menu-expansion-panel-header disabled-hover ps-2">
                        <mat-panel-title>
                          <div class="menu-item d-flex align-items-center">
                            <span
                            class="material-symbols-outlined icon-seccion primary-500 me-2">{{seccion.icon}}</span>
                            <span
                            class="all-menu mb-0 primary-800">{{seccion.nombre}}</span>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="row">
                        @for (subseccion of seccion.subsecciones; track subseccion) {
                          <div class="col-12">
                            <!-- Lineas de inversión (SEGOFINANCE) -->
                            @if (plataforma === 'segofinance') {
                              @if (seccion.id === 'financiacion-participativa' || seccion.id === 'otros-productos') {
                                <div class="row">
                                  <div class="linea-separadora-1 border-primary-100 my-2"></div>
                                  <div class="col-12">
                                    <div class="menu-subitem">
                                      <a href="{{subseccion.route}}"
                                        target="{{seccion.id === 'financiacion-participativa' ? '_blank' : '_self'}}"
                                        class="link-producto all-menu mb-0 primary-800 d-flex align-items-center h-100 pointer"
                                        title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                                        [mat-dialog-close]="true">
                                        <div
                                          class="me-2 circle-container-36 d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                                          <span
                                          class="material-symbols-outlined primary-50">{{subseccion.icon}}</span>
                                        </div>
                                        <div class="d-inline tab-ancla mb-0"
                                        [innerHTML]="subseccion.nombre"></div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              }
                            }
                            <!-- Lineas de inversión (CNMV) -->
                            @if ((plataforma === 'venture' || plataforma === 'realestate' || plataforma === 'creative') && seccion.id === 'financiacion-participativa') {
                              <div class="row">
                                <div class="linea-separadora-1 border-primary-100 my-2"></div>
                                <div class="col-12">
                                  <div class="menu-subitem">
                                    @if (subseccion.linkType === 'routerLink') {
                                      <a
                                        routerLink="{{subseccion.route}}"
                                        class="link-producto all-menu mb-0 primary-800 d-flex align-items-center h-100 pointer"
                                        title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                                        [mat-dialog-close]="true">
                                        <div
                                          class="me-2 circle-container-36 d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                                          <span
                                          class="material-symbols-outlined primary-50">{{subseccion.icon}}</span>
                                        </div>
                                        <div class="d-inline tab-ancla mb-0"
                                        [innerHTML]="subseccion.nombre"></div>
                                        <div class="d-inline bullet-before p3-medium mb-0 ms-2">Panel del
                                        inversor</div>
                                      </a>
                                    }
                                    @if (subseccion.linkType === 'href') {
                                      <a
                                        href="{{subseccion.route}}"
                                        class="link-producto all-menu mb-0 primary-800 d-flex align-items-center h-100 pointer"
                                        title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                                        [mat-dialog-close]="true">
                                        <div
                                          class="me-2 circle-container-36 d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                                          <span
                                          class="material-symbols-outlined primary-50">{{subseccion.icon}}</span>
                                        </div>
                                        <div class="d-inline tab-ancla mb-0"
                                        [innerHTML]="subseccion.nombre"></div>
                                        <div class="d-inline bullet-before p3-medium mb-0 ms-2">Panel del
                                        inversor</div>
                                      </a>
                                    }
                                  </div>
                                </div>
                                <div class="linea-separadora-1 border-primary-100 my-2"></div>
                              </div>
                              <div class="row">
                                @for (link of subseccion.links; track link; let isLastLink = $last) {
                                  <div class="col-12">
                                    <div class="menu-subitem item-route">
                                      @if (link.route && subseccion.linkType === 'routerLink') {
                                        <a
                                          routerLink="{{link.route}}" title="{{link.nombre}}"
                                          class="all-menu primary-800 d-flex align-items-center h-100"
                                          [mat-dialog-close]="true">
                                          <div class="d-flex align-items-center">
                                            {{link.nombre}}
                                            @if (link.isNew) {
                                              <div class="ms-2">
                                                <app-tag-new></app-tag-new>
                                              </div>
                                            }
                                          </div>
                                        </a>
                                      }
                                      @if (link.route && subseccion.linkType === 'href') {
                                        <a
                                          href="{{link.route}}" title="{{link.nombre}}"
                                          class="all-menu primary-800 d-flex align-items-center h-100"
                                          [mat-dialog-close]="true">
                                          <div class="d-flex align-items-center">
                                            {{link.nombre}}
                                            @if (link.isNew) {
                                              <div class="ms-2">
                                                <app-tag-new></app-tag-new>
                                              </div>
                                            }
                                          </div>
                                        </a>
                                      }
                                    </div>
                                  </div>
                                  @if (!isLastLink) {
                                    <div
                                    class="linea-separadora-1 border-primary-100 my-2"></div>
                                  }
                                }
                              </div>
                            }
                            <!-- Lineas de inversión (Factoring, Funds) -->
                            @if ((plataforma === 'factoring' || plataforma === 'funds')) {
                              @if (seccion.id === 'productos') {
                                <div class="row">
                                  <div class="linea-separadora-1 border-primary-100 my-2"></div>
                                  <div class="col-12">
                                    <div class="menu-subitem">
                                      <a routerLink="{{subseccion.route}}"
                                        class="link-producto all-menu mb-0 primary-800 d-flex align-items-center h-100 pointer"
                                        title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                                        [mat-dialog-close]="true">
                                        <div
                                          class="me-2 circle-container-36 d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                                          <span
                                          class="material-symbols-outlined primary-50">{{subseccion.icon}}</span>
                                        </div>
                                        <div class="d-inline tab-ancla mb-0"
                                        [innerHTML]="subseccion.nombre"></div>
                                        <div class="d-inline bullet-before p3-medium mb-0 ms-2">Panel
                                          del
                                        inversor</div>
                                      </a>
                                    </div>
                                  </div>
                                  <div class="linea-separadora-1 border-primary-100 my-2"></div>
                                </div>
                              }
                              @if (seccion.id === 'otros-productos-funds-factoring') {
                                <div class="row">
                                  <div class="linea-separadora-1 border-primary-100 my-2"></div>
                                  <div class="col-12">
                                    @if (subseccion.id === 'factoring' || subseccion.id === 'funds') {
                                      <div class="menu-subitem"
                                        >
                                        <a href="javascript:void(0)"
                                          (click)="openPopupCnmv(subseccion.id, subseccion.route)"
                                          class="link-producto all-menu mb-0 primary-800 d-flex align-items-center h-100 pointer"
                                          title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                                          [mat-dialog-close]="true">
                                          <div
                                            class="me-2 circle-container-36 d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                                            <span
                                            class="material-symbols-outlined primary-50">{{subseccion.icon}}</span>
                                          </div>
                                          <div class="d-inline tab-ancla mb-0"
                                          [innerHTML]="subseccion.nombre"></div>
                                        </a>
                                      </div>
                                    }
                                    @if (subseccion.id === 'segofinance') {
                                      <div class="menu-subitem"
                                        >
                                        <a href="javascript:void(0)"
                                          (click)="openPopupCnmv(subseccion.id, subseccion.route)"
                                          class="link-producto all-menu mb-0 primary-800 d-flex align-items-center h-100 pointer"
                                          title="Grupo SEGOFINANCE" [mat-dialog-close]="true">
                                          <div
                                            class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start">
                                            <img src="./assets/images/SegoFinance_HN.svg"
                                              class="sego-logo" alt="Grupo SEGOFINANCE"
                                              title="Grupo SEGOFINANCE">
                                            </div>
                                          </a>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                                @if (seccion.id === 'financiacion-participativa' || seccion.id === 'otros-productos' || seccion.id === 'productos' || seccion.id === 'otros-productos-funds-factoring') {
                                  <div
                                    class="row">
                                    @for (link of subseccion.links; track link; let isLastLink = $last) {
                                      <div
                                        class="col-12">
                                        <div class="menu-subitem item-route">
                                          @if (link.route && subseccion.linkType === 'routerLink') {
                                            <a
                                              routerLink="{{link.route}}" title="{{link.nombre}}"
                                              class="link-route all-menu primary-800 d-flex align-items-center h-100"
                                              [mat-dialog-close]="true">
                                              <div class="d-flex align-items-center">
                                                {{link.nombre}}
                                                @if (link.isNew) {
                                                  <div class="ms-2">
                                                    <app-tag-new></app-tag-new>
                                                  </div>
                                                }
                                              </div>
                                            </a>
                                          }
                                          @if (link.route && subseccion.linkType === 'href') {
                                            <a
                                              href="{{link.route}}" title="{{link.nombre}}"
                                              class="link-route all-menu primary-800 d-flex align-items-center h-100"
                                              [mat-dialog-close]="true">
                                              <div class="d-flex align-items-center">
                                                {{link.nombre}}
                                                @if (link.isNew) {
                                                  <div class="ms-2">
                                                    <app-tag-new></app-tag-new>
                                                  </div>
                                                }
                                              </div>
                                            </a>
                                          }
                                        </div>
                                        @if (!isLastLink) {
                                          <div
                                            class="linea-separadora-1 border-primary-100 my-2">
                                          </div>
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              }
                              <!-- Otras secciones -->
                              @if (seccion.id !== 'financiacion-participativa' && seccion.id !== 'otros-productos' && seccion.id !== 'productos' && seccion.id !== 'otros-productos-funds-factoring') {
                                <div class="linea-separadora-1 border-primary-100 my-2"></div>
                                <div class="row">
                                  @for (link of subseccion.links; track link; let isLastLink = $last) {
                                    <div
                                      class="col-12">
                                      <div class="menu-subitem item-route">
                                        @if (link.route && !link.url) {
                                          <a routerLink="{{link.route}}"
                                            title="{{link.nombre}}"
                                            class="link-route all-menu primary-800 d-flex align-items-center h-100"
                                            [queryParams]="{'filter': link.routeParam}"
                                            [mat-dialog-close]="true">
                                            {{link.nombre}}
                                          </a>
                                        }
                                        @if (link.url && !link.route) {
                                          <a href="{{link.url}}"
                                            target="_blank" title="{{link.nombre}}"
                                            class="link-route all-menu primary-800 d-flex align-items-center h-100"
                                            [mat-dialog-close]="true">
                                            {{link.nombre}}
                                          </a>
                                        }
                                      </div>
                                      @if (!isLastLink) {
                                        <div
                                          class="linea-separadora-1 border-primary-100 my-2">
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                          }
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <div class="linea-separadora-1 border-primary-100 my-2"></div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
        <!-- Contacto -->
        @if (datosContacto) {
          <div class="row mt-auto menu-contacto py-5">
            <div class="col-12 text-center">
              <p class="p2-medium primary-800">Para cualquier duda, contáctanos:</p>
              @for (contacto of datosContacto; track contacto) {
                <div class="mt-4 px-4 d-inline-block">
                  <a href="{{contacto.href}}" target="_blank" role="button" title="{{contacto.title}}"
                    class="d-flex justify-content-center">
                    <div
                      class="btn-menu-contacto shadow-32 border-radius-200 bg-primary-800 d-flex align-items-center justify-content-center">
                      <span
                      class="material-symbols-outlined icon-menu-contacto primary-100">{{contacto.icon}}</span>
                    </div>
                  </a>
                </div>
              }
            </div>
          </div>
        }
      </section>
    }
  </div>