import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { SharedModule } from 'src/app/_shared/_shared.module';

@Component({
  selector: 'app-acceso-usuario-mobile',
  templateUrl: './acceso-usuario-mobile.component.html',
  styleUrls: ['./acceso-usuario-mobile.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})


export class AccesoUsuarioMobileComponent implements OnInit {

  returnUrlParam: string;
  plataformaId: string;
  domainSegoUrl: string;

  constructor(
    private _accesoBottomRef: MatBottomSheetRef<AccesoUsuarioMobileComponent>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _usuarioService: UsuarioService,
    private _platformService: PlatformService
    ) {
      this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
      this.domainSegoUrl = this._platformService.getSubdominioUrl('segofinance');
  }

  ngOnInit(): void {
    this.getReturnUrl();
  }
  
  private getReturnUrl(): void {
    // Si la ruta es la del registro/login no asignamos el param
    const currentUrl = this._router.url.split('?')[0];
    const params = this._activatedRoute.snapshot.queryParamMap;
    if (currentUrl === '/login' || currentUrl === '/registro' || currentUrl === '/') {
      if (params.has('returnUrl')) {
        this.returnUrlParam = params.get('returnUrl');
      } else {
        this.returnUrlParam = null;
      }
    } else {
      this.returnUrlParam = currentUrl.split('?')[0];
    }
  }

  closeMenu(): void {
    this._accesoBottomRef.dismiss();
  }
}
