import { Component, Input } from '@angular/core';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { Seccion } from 'src/app/header/_models/seccion';
import { MenuDesktopComponent } from 'src/app/header/menu-opened/menu-desktop/menu-desktop.component';
import { LogoPlataformaComponent } from 'src/app/header/utils/logo-plataforma/logo-plataforma.component';
import { AccesoUsuarioComponent } from 'src/app/header/utils/acceso-usuario/acceso-usuario.component';
import { PerfilUsuarioComponent } from 'src/app/header/utils/perfil-usuario/perfil-usuario.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    LogoPlataformaComponent,
    AccesoUsuarioComponent,
    PerfilUsuarioComponent,
    MenuDesktopComponent
  ]
})

export class HeaderDesktopComponent 
{
  @Input() isLogged: boolean;
  @Input() plataforma: string;
  @Input() secciones: Seccion[];
  isSeccionOpened: string;


  showSeccion(seccion: string)
  {
    this.isSeccionOpened = seccion;
  }

  hideSeccion()
  {
    this.isSeccionOpened = null;
  }

  toggleSeccion(seccion: string)
  {
    if (this.isSeccionOpened === seccion)
    {
      this.hideSeccion();
    } else
    {
      this.showSeccion(seccion);
    }
  }
}

