<div id="header-tablet" class="row align-items-center">
  <div class="col-logo">
    <button class="btn btn-secondary border-2" (click)="openDialogMenu()">
      <div class="d-flex align-items-center">
        <span class="material-symbols-outlined">menu</span>
        <span class="ms-2">Menú</span>
      </div>
    </button>
  </div>
  <div class="col-sections">
    <app-logo-plataforma [plataforma]="plataforma"></app-logo-plataforma>
  </div>
  <div class="col-perfil text-end pe-0">
    @if (!isLogged) {
      <app-acceso-usuario></app-acceso-usuario>
    }
    @if (isLogged) {
      <app-perfil-usuario></app-perfil-usuario>
    }
  </div>
</div>