<div id="header-desktop" class="row align-items-center" (mouseleave)="hideSeccion()">
  <div class="col-logo pe-3"  (mouseenter)="hideSeccion()">
    <app-logo-plataforma [plataforma]="plataforma"></app-logo-plataforma>
  </div>
  <div class="col-sections">
    <div class="row gx-0 align-items-center justify-content-center">
      @for (seccion of secciones; track seccion.id) {
        <div class="{{seccion.col}}">
          <!-- Seccion sin menu desplegable -->
          @if (!seccion.subsecciones) {
            <a class="all-menu primary-800 text-center d-inline" routerLink="{{seccion.route}}" [routerLinkActive]="'font-weight-600'" title="{{seccion.nombre}}" (mouseenter)="hideSeccion()">
              {{seccion.nombre}}
            </a>
          }
          <!-- Seccion con menu desplegable -->
          @if (seccion.subsecciones) {
            <div>
              <a class="all-menu primary-800" role="button" title="{{seccion.nombre}}" (click)="toggleSeccion(seccion.id)" (mouseenter)="showSeccion(seccion.id)">
                <div class="d-flex align-items-center justify-content-center">
                  {{seccion.nombre}}
                  <span class="primary-800 ms-1 material-symbols-outlined filled">{{isSeccionOpened !== seccion.id ? 'arrow_drop_down' : 'arrow_drop_down_circle'}}</span>
                </div>
              </a>
              @if (isSeccionOpened === (seccion.id)) {
                <div class="submenu-opened {{seccion.id}} plataforma-{{plataforma}}" (mouseleave)="hideSeccion()">
                  <app-menu-desktop [plataforma]="plataforma" [seccion]="seccion" [subsecciones]="seccion.subsecciones" (closeMenu)="hideSeccion()"></app-menu-desktop>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
  <div class="col-perfil ps-3 text-end" (mouseenter)="hideSeccion()">
    @if (!isLogged) {
      <app-acceso-usuario (accesoOpened)="hideSeccion()" [isSeccionOpened]="isSeccionOpened"></app-acceso-usuario>
    }
    @if (isLogged) {
      <app-perfil-usuario (perfilOpened)="hideSeccion()" [isSeccionOpened]="isSeccionOpened"></app-perfil-usuario>
    }
  </div>
</div>