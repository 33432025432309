<!-- Desktop -->
<div id="button-acceso" class="d-none d-md-block">
  <button class="btn btn-primary px-3" title="Acceso" (click)="toggleMenu()" (clickOutside)="hideMenu()">
    <div class="d-flex align-items-center">
      <strong class="me-1">Acceso</strong>
      <span class="material-symbols-outlined">{{isMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}</span>
    </div>
  </button>

  @if (isMenuOpen) {
    <div class="menu-acceso-opened">
      <div id="menu-acceso" class="bg-primary-50 border-radius-4 shadow-8 py-2 fadeinMenu">
        <div class="row">
          <div class="col-12">
            <div class="px-3 acceso-item">
              <div class="d-flex align-items-center text-start h-100 ">
                <a routerLink="/login" class="link1-semibold mb-0 primary-800 font-weight-600 text-decoration-underline d-inline-flex align-items-center h-100" [queryParams]="{returnUrl: returnUrlParam}" title="Acceder">
                  Acceder
                </a>
                <span class="p1-medium mb-0 primary-800 px-2">|</span>
                <a routerLink="/registro" class="link1-semibold mb-0 primary-800 font-weight-600 text-decoration-underline d-inline-flex align-items-center h-100" [queryParams]="{returnUrl: returnUrlParam}" title="Registrarse">
                  Registrarse
                </a>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="px-3 acceso-item">
              @if (plataformaId === 'SEGOFINANCE') {
                <a routerLink="/login" class="p1-medium primary-800" [queryParams]="{returnUrl: '/panel-inversor/info-usuario'}" title="Mi cuenta">
                  <div class="d-flex align-items-center h-100">
                    Mi cuenta
                  </div>
                </a>
              }
              @if (plataformaId !== 'SEGOFINANCE') {
                <a  href="{{domainSegoUrl + '/panel-inversor/info-usuario'}}" class="p1-medium primary-800" title="Mi cuenta">
                  <div class="d-flex align-items-center h-100">
                    Mi cuenta
                  </div>
                </a>
              }
            </div>
          </div>
          <div class="col-12">
            <div class="px-3 acceso-item">
              @if (plataformaId === 'SEGOFINANCE') {
                <a routerLink="/panel-inversor/posicion-global" class="p1-medium primary-800" title="Posicion Global">
                  <div class="d-flex align-items-center h-100">
                    Posición Global
                  </div>
                </a>
              }
              @if (plataformaId !== 'SEGOFINANCE') {
                <a href="{{domainSegoUrl + '/panel-inversor/posicion-global'}}" class="p1-medium primary-800" title="Posicion Global">
                  <div class="d-flex align-items-center h-100">
                    Posición Global
                  </div>
                </a>
              }
            </div>
          </div>
          <div class="col-12">
            <div class="px-3 acceso-item">
              @if (plataformaId === 'SEGOFINANCE') {
                <a routerLink="/login" class="p1-medium primary-800" [queryParams]="{returnUrl: '/panel-inversor/info-usuario/wallet'}" title="Mi wallet">
                  <div class="d-flex align-items-center h-100">
                    Mi wallet
                  </div>
                </a>
              }
              @if (plataformaId !== 'SEGOFINANCE') {
                <a  href="{{domainSegoUrl + '/panel-inversor/info-usuario/wallet'}}" class="p1-medium primary-800" title="Mi wallet">
                  <div class="d-flex align-items-center h-100">
                    Mi wallet
                  </div>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>

<!-- Mobile -->
<div id="button-acceso-mb" class="d-block d-md-none">
  <button class="btn-acceso bg-transparent px-0" (click)="showMenuMobile()">
    <div class="d-flex align-items-center justify-content-center acceso-circle bg-primary-900 primary-50 border-radius-50">
      <span class="material-symbols-outlined filled icon-acceso primary-50">person</span>
    </div>
  </button>
</div>