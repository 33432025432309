<div id="botones-flotantes">
  <!-- PC -->
  <div class="d-none d-lg-block">
    <div class="row">
      <div class="col-1 ms-auto px-0 text-end fixed-bottom fixed-feedback">
        <button (click)="toggleFeedback()" class="circle-container-48 bg-primary-500 btn-feedback me-4"
          title="Ayúdenos a mejorar">
          <div class="d-flex align-items-center justify-content-center">
            <span class="material-symbols-outlined mat-icon-size-24 neutral-0">chat</span>
          </div>
        </button>
      </div>
    </div>
    <div [hidden]="!isFeedbackOpen" class="feedbackForm bg-primary-50 border-radius-8 fadeinMenu p-4">
      <div class="container-fluid gx-0">
        <div class="row">
          <div class="col-12 px-0 text-end">
            <button (click)="toggleFeedback()" class="bg-transparent" title="Cerrar">
              <span class="material-symbols-outlined primary-900">close</span>
            </button>
          </div>
          <div class="col-12">
            <h5 class="text-center">Ayúdanos a mejorar</h5>
            <div class="my-2 text-center">
              <span class="material-symbols-outlined primary-800 rotate-180 mat-icon-size-32"
                title="Ayúdenos a mejorar">wb_incandescent</span>
            </div>
            <form [formGroup]="feedbackWebForm">
              <mat-form-field class="neutral-500 mb-2" appearance="outline" floatLabel="always">
                <mat-label>Comentario</mat-label>
                <textarea matInput id="comentario" type="text" name="comentario" formControlName="comentario"
                  placeholder="¿Alguna sugerencia?" maxlength="255"></textarea>
                <mat-hint>El número de caracteres debe estar entre 10 y 255</mat-hint>
                @if (feedbackWebForm.controls.comentario.touched) {
                <mat-error>
                  @if (feedbackWebForm.controls.comentario.errors?.required) {
                  <span>
                    * Campo obligatorio
                  </span>
                  }
                  @if (feedbackWebForm.controls.comentario.errors?.minlength) {
                  <span>
                    El mínimo de caracteres debe ser 10.
                  </span>
                  }
                  @if (feedbackWebForm.controls.comentario.errors?.maxlength) {
                  <span>
                    El máximo de caracteres debe ser 255.
                  </span>
                  }
                </mat-error>
                }
              </mat-form-field>
              <mat-form-field class="neutral-500 mt-2" appearance="outline" floatLabel="always" [hidden]="isLogged">
                <mat-label>Email</mat-label>
                <input matInput id="emailFeedback" name="email" formControlName="email" placeholder="Tu email">
                @if (feedbackWebForm.controls.email.touched) {
                <mat-error>
                  @if (feedbackWebForm.controls.email.errors?.required) {
                  <span>* Campo obligatorio</span>
                  }
                  @if (feedbackWebForm.controls.email.errors?.email) {
                  <span>Email no válido</span>
                  }
                </mat-error>
                }
              </mat-form-field>
              <mat-checkbox (change)="setAnonimo($event.checked)" id="isAnonimo" formControlName="isAnonimo"
                name="isAnonimo" title="Enviar como anónimo">
                <span class="ms-1 primary-900 p1-regular mb-0">Enviar como anónimo</span>
              </mat-checkbox>
            </form>
            <div class="mt-4 text-center">
              <button class="btn btn-primary w-100 text-center" [disabled]="!feedbackWebForm.valid || isLoading"
                (click)="sendFeedback()" title="Enviar">
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile -->
  <div class="d-block d-lg-none fixed-bottom btn-contactar">
    <div class="row p-2"
      [hidden]="isContactarHidden || isLogged || (isRutaCnmv === true) || plataformaId === 'FUNDS' || plataformaId === 'FACTORING'">
      <div class="col-12 text-center my-2">
        <button class="btn btn-primary p-0" type="button">
          <div class="d-flex align-items-center justify-content-center">
            <a href="https://outlook.office365.com/book/SEGOInversores@segofinance.com/" target="_blank" class="p-2 ps-3 primary-50 font-primary">
              Contacta con un experto
            </a>
            <span (click)="hideContactar()" class="material-symbols-outlined primary-50 iconClose me-2">close</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>