<header class="container-fluid-100 fixed-top">
  <nav class="navbar-redes-sociales bg-primary-900 d-none d-xl-block">
    <app-navbar-social></app-navbar-social>
  </nav>

  @if (plataformaId) {
    <nav class="navbar navbar-segofinance d-block shadow-8 px-3">
      <app-header-desktop class="d-none d-xl-block" [isLogged]="isLogged" [plataforma]="plataformaId" [secciones]="secciones"></app-header-desktop>
      <app-header-tablet class="d-none d-md-block d-xl-none" [isLogged]="isLogged" [plataforma]="plataformaId" [secciones]="secciones"></app-header-tablet>
      <app-header-mobile class="d-block d-md-none" [isLogged]="isLogged" [plataforma]="plataformaId" [secciones]="secciones"></app-header-mobile>
    </nav>
  }

  @if (isLogged) {
    <nav>
      <app-wallet-bar></app-wallet-bar>
    </nav>
  }
</header>