import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { SnackBarErrorConfig } from 'src/app/_utils/_material-import/snackbar-config';
import { PerfilInversor } from 'src/app/header/_models/perfil-inversor';
import { InformacionBasicaService } from 'src/app/panel-inversor/_services/informacion-basica.service';
import { RouterModule } from '@angular/router';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { SaldoWalletComponent } from 'src/app/_shared/bloque-wallet-nuevo/wallet-bar/saldo-wallet/saldo-wallet.component';

@Component({
  selector: 'app-wallet-bar',
  templateUrl: './wallet-bar.component.html',
  styleUrls: ['./wallet-bar.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    SaldoWalletComponent
  ]
})
export class WalletBarComponent implements OnInit {

  isLoading: boolean = false;
  perfilInversor: PerfilInversor;
  plataformaId: string;
  domainSegoUrl: string;
  
  constructor(
    private _usuarioService: UsuarioService,
    private _platformService: PlatformService,
    private _informacionBasicaService: InformacionBasicaService,
    private _matSnackBar: MatSnackBar
  ) { 
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
    this.domainSegoUrl = this._platformService.getSubdominioUrl('segofinance');
  }

  ngOnInit(): void {
    this.getPerfilInversor();
  }

  private getPerfilInversor(): void 
  {
    this.isLoading = true;
    this._informacionBasicaService.get().subscribe({
      next: (r) => {
        this.isLoading = false;
        this.perfilInversor = r;
      },
      error: (e) => {
        this.isLoading = false;
        this._matSnackBar.open(this._informacionBasicaService.getServerErrors(e), 'x', new SnackBarErrorConfig());
      }
    });
  }
}
