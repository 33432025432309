import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { FeedbackWeb } from 'src/app/home/_models/feedback-web';



@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends GenericService<FeedbackWeb>{

  constructor(http: HttpClient) {
    super(http, "Feedback", "v1");
  }

  enviarFeedback(feedbackWeb: FeedbackWeb): Observable<FeedbackWeb>
  {
    return this._http.post<FeedbackWeb>(`${this._apiCoreUrl}v1/Feedback`, feedbackWeb)
  }
}
