<div class="container-fluid  bg-secondary-venture-300">
    <div class="container px-3 px-sm-0">
        <div class="row">
            <div class="col-12 py-5">
                <p class="t2-m-bold neutral-900">Invertir en Startups y Empresas en fases iniciales conlleva
                    riesgos, los cuales incluyen falta de liquidez, ausencia de dividendos, pérdida de la inversión o
                    dilución de las participaciones/acciones, entre otros. Por ello, es sumamente recomendable que,
                    antes de comenzar a operar con nosotros, se informe al respecto de dichos riesgos a fin de poder
                    tomar decisiones de inversión conscientes y meditadas.</p>
                <p class="t2-m-bold neutral-900 mb-0">Por favor, siga el siguiente <a
                        [routerLink]="['legal/startups/condiciones-generales']" target="_blank"
                        class="primary-900 text-decoration-underline" title="Términos y condiciones">enlace</a> para
                    mayor información sobre los riesgos inherentes a este tipo de actividad.</p>
            </div>
        </div>
    </div>
</div>