import { Component, OnInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TarifasFactoringComponent } from 'src/app/footer/factoring/tarifas-factoring/tarifas-factoring.component';
import { TarifasRealestateComponent } from 'src/app/footer/realestate/tarifas-realestate/tarifas-realestate.component';
import { TarifasVentureComponent } from 'src/app/footer/venture/tarifas-venture/tarifas-venture.component';
import { PlatformService } from 'src/app/_services/platform.service';
import { Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { FooterPie } from 'src/app/footer/_models/footer-pie';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { TarifasCreativeComponent } from 'src/app/footer/creative/tarifas-creative/tarifas-creative.component';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})

export class FooterComponent implements OnInit
{
  @Input() isInfoCreative: boolean;
  template: 'global' | 'cnmv' | 'no-cnmv' = 'global';
  plataformaId: string;
  segoDomainUrl: string;
  ventureDomainUrl: string;
  realestateDomainUrl: string;
  creativeDomainUrl: string;
  factoringDomainUrl: string;
  fundsDomainUrl: string;
  footerPie: FooterPie;
  panelEmprendedorDomainUrl: string;
  isHorarioVerano: boolean = false;
  yearCopyright: number = new Date().getFullYear();


  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _router: Router,
    private _dialog: MatDialog,
    private _platformService: PlatformService,
  )
  {
    this._router.events.subscribe((event: Event) =>
    {
      if (event instanceof NavigationEnd)
      {
        if (isPlatformBrowser(this._platformId))
        {
          this.getPlataformaDomain();
        }
      }
    })
  }

  ngOnInit(): void
  {
    this.getFooterPieInfo();
    this.getPlataformaDomain();
    this.checkHorarioVerano();
  }

  checkHorarioVerano(): void
  {
    const today = new Date();
    const start = new Date(today.getFullYear(), 6, 2);
    const end = new Date(today.getFullYear(), 7, 32);
    this.isHorarioVerano = today.getTime() >= start.getTime() && today.getTime() <= end.getTime();
  }

  private getPlataformaDomain(): void
  {
    this.segoDomainUrl = this._platformService.getSubdominioUrl('segofinance');
    this.ventureDomainUrl = this._platformService.getSubdominioUrl('venture');
    this.realestateDomainUrl = this._platformService.getSubdominioUrl('realestate');
    this.creativeDomainUrl = this._platformService.getSubdominioUrl('creative');
    this.factoringDomainUrl = this._platformService.getSubdominioUrl('factoring');
    this.fundsDomainUrl = this._platformService.getSubdominioUrl('funds');
    this.panelEmprendedorDomainUrl = this._platformService.getSubdominioUrl('panel-emprendedor');
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
    switch (this.plataformaId)
    {
      case 'VENTURE':
        this.template = 'cnmv';
        break;
      case 'REALESTATE':
        this.template = 'cnmv';
        break;
      case 'FACTORING':
        this.template = 'no-cnmv';
        break;
      case 'FUNDS':
        this.template = 'no-cnmv';
        break;
      case 'CREATIVE':
        this.template = 'cnmv';
        break;
      case 'SEGOFINANCE':
      default:
        this.template = this.isInfoCreative ? 'cnmv' : 'global';
        break;
    }
  }

  private getFooterPieInfo(): void
  {
    this.footerPie = {
      sociosinversores: "<strong class='p3-semibold'>SEGO Venture</strong>, <strong class='p3-semibold'>SEGO Real Estate</strong> y <strong class='p3-semibold'>SEGO Creative</strong> son productos ofrecidos por <strong class='p3-semibold'>SociosInversores 2010 SL</strong>, sociedad del Grupo SegoFinance y Proveedor de Servicios de Financiación Participativa, <a href='https://www.cnmv.es/portal/Consultas/Servicios-Financiacion-Participativa/Listado-Proveedores.aspx' target='_blank' class='link3-semibold primary-100' title='Comisión Nacional del Mercado de Valores (CNMV)'>supervisado por CNMV</a> y registrado en la Autoridad Europea de Supervisión de Valores y Mercados (AEVM) con Licencia nº 7. Sujeto al Reglamento (UE) 2020/1503 del Parlamento Europeo.",
      factoring: "<strong class='p3-semibold'>SEGO Factoring</strong> es un producto ofrecido por <strong class='p3-semibold'>Smart and Small SL</strong>, sociedad del grupo SegoFinance. No sujeto al Reglamento (UE) 2020/1503 del Parlamento Europeo y por tanto, no está supervisado por CNMV.",
      funds: "<strong class='p3-semibold'>SEGO Funds</strong> es un producto ofrecido por <strong class='p3-semibold'>Fintup Digital S.L</strong>, sociedad del grupo SegoFinance, y Agente Financiero de MyInvestor Banco S.A., y Agente de Seguros de MEDVIDA Partners, con Código mediador: C0559B87860854."
    }
  }

  openAvisoSalidaCNMV(plataformaDestino: string, url: string): void
  {
    this._platformService.openAvisoSalidaCnmv(this.plataformaId.toLocaleLowerCase(), plataformaDestino, url);
  }

  openTarifasVenture()
  {
    const venturePopup = this._dialog.open(TarifasVentureComponent, {
      autoFocus: false,
      panelClass: 'dialog-tarifas',
      data: { domainUrl: this.ventureDomainUrl, plataformaId: this.plataformaId }
    });
  }

  openTarifasFactoring()
  {
    const factoringPopup = this._dialog.open(TarifasFactoringComponent, {
      autoFocus: false,
      panelClass: 'dialog-tarifas',
      data: { domainUrl: this.factoringDomainUrl, plataformaId: this.plataformaId }
    });
  }

  openTarifasRealEstate()
  {
    const realEstatePopup = this._dialog.open(TarifasRealestateComponent, {
      autoFocus: false,
      panelClass: 'dialog-tarifas',
      data: { domainUrl: this.realestateDomainUrl, plataformaId: this.plataformaId }
    });
  }

  openTarifasCreative()
  {
    const creativePopup = this._dialog.open(TarifasCreativeComponent, {
      autoFocus: false,
      panelClass: 'dialog-tarifas',
      data: { domainUrl: this.creativeDomainUrl, plataformaId: this.plataformaId }
    });
  }

  openAvisoSalida(link: string): void
  {
    this._platformService.openAvisoSalidaCnmv(this.plataformaId.toLocaleLowerCase(), 'segofinance', link);
  }
}
