import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { AccesoUsuarioMobileComponent } from 'src/app/header/utils/acceso-usuario-mobile/acceso-usuario-mobile.component';

@Component({
  selector: 'app-acceso-usuario',
  templateUrl: './acceso-usuario.component.html',
  styleUrls: ['./acceso-usuario.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    NgClickOutsideDirective
  ]
})


export class AccesoUsuarioComponent implements OnInit {
  @Input() isSeccionOpened: string;
  @Output() accesoOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  isMenuOpen: boolean = false;
  plataformaId: string;
  domainSegoUrl: string;
  returnUrlParam: string;

  constructor(
    private _matBottomSheet: MatBottomSheet,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _platformService: PlatformService
  ) { 
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
    this.domainSegoUrl = this._platformService.getSubdominioUrl('segofinance');
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Cierra el menu de acceso si se abre alguna sección del menu (desktop)
    if(changes.isSeccionOpened.currentValue && this.isSeccionOpened){
      this.hideMenu();
    }
  }

  ngOnInit(): void {
    this.getReturnUrl();
  }

  showMenu(): void {
    this.isMenuOpen = true;
    this.accesoOpened.emit(true);
  }

  hideMenu(): void {
    this.isMenuOpen = false;
  } 

  toggleMenu(): void {
    if(this.isMenuOpen === true){
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  showMenuMobile(): void {
    this._matBottomSheet.open(AccesoUsuarioMobileComponent, { autoFocus: false });
  }

  private getReturnUrl(): void {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {   
        // Si la ruta es la del registro/login no asignamos el param
        const currentUrl = this._router.url.split('?')[0];
        const params = this._activatedRoute.snapshot.queryParamMap;
        if (currentUrl === '/login' || currentUrl === '/registro' || currentUrl === '/') {
          if (params.has('returnUrl')) {
            this.returnUrlParam = params.get('returnUrl');
          } else {
            this.returnUrlParam = null;
          }
        } else {
          this.returnUrlParam = currentUrl.split('?')[0];
        }
      }
    });
  }
}
