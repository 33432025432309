import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { RedSocial } from 'src/app/header/_models/red-social';

@Component({
  selector: 'app-navbar-social',
  templateUrl: './navbar-social.component.html',
  styleUrls: ['./navbar-social.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})

export class NavbarSocialComponent implements OnInit {
  redesSociales: RedSocial[];
  constructor() { }

  ngOnInit(): void {
    this.getSocialIcons();
  }

  private getSocialIcons(): void {
    this.redesSociales = [
      {
        nombre: "Linkedin",
        icono: "assets/images/linkedin.svg",
        link: "https://www.linkedin.com/company/sego-finance"
      },
      {
        nombre: "Instagram",
        icono: "assets/images/instagram.svg",
        link: "https://www.instagram.com/segofinance/"
      },
      {
        nombre: "Twitter",
        icono: "assets/images/twitter.svg",
        link: "https://twitter.com/SegoFinance"
      },
      {
        nombre: "Facebook",
        icono: "assets/images/facebook.svg",
        link: "https://www.facebook.com/segofinance/"
      }
    ]
  }
}
