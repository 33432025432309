<div class="container-logo">
  <div class="d-flex align-items-center justify-content-center justify-content-xl-start h-100">
    @if (plataforma === 'segofinance') {
      <a routerLink="/">
        <img src="./assets/images/SegoFinance_HN.svg" class="header-logo" alt="Grupo SEGOFINANCE"
          title="Grupo SEGOFINANCE">
        </a>
      }
      @if (plataforma === 'venture') {
        <a routerLink="/">
          <img src="./assets/images/venture/SegoVenture_HN.svg" class="header-logo" alt="SEGO Venture"
            title="SEGO Venture">
          </a>
        }
        @if (plataforma === 'realestate') {
          <a routerLink="/">
            <img src="./assets/images/realestate/SegoRealEstate_HN.svg" class="header-logo" alt="SEGO RealEstate"
              title="SEGO RealEstate">
            </a>
          }
          @if (plataforma === 'factoring') {
            <a routerLink="/">
              <img src="./assets/images/factoring/SegoFactoring_HN.svg" class="header-logo" alt="SEGO Factoring"
                title="SEGO Factoring">
              </a>
            }
            @if (plataforma === 'funds') {
              <a routerLink="/">
                <img src="./assets/images/funds/SegoFunds_HN.svg" class="header-logo" alt="SEGO Funds" title="SEGO Funds">
              </a>
            }
            @if (plataforma === 'creative') {
              <a routerLink="/">
                <img src="./assets/images/creative/sego_creative.svg" class="header-logo d-none d-lg-block" alt="SEGO Creative" title="SEGO Creative">
                <img src="./assets/images/creative/sego_creative_mobile.svg" class="header-logo d-block d-lg-none" alt="SEGO Creative" title="SEGO Creative">
              </a>
            }
          </div>
        </div>