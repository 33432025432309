import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';

@Component({
  selector: 'app-logo-plataforma',
  templateUrl: './logo-plataforma.component.html',
  styleUrls: ['./logo-plataforma.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})

export class LogoPlataformaComponent {
  @Input() plataforma: string;
}
