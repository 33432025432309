import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class SeoService
{
    canonicas =
        {
            _: {
                link: "https://www.segofinance.com"
            }, 
            _quienes_somos: {
                link: "https://www.segofinance.com/quienes-somos"
            },
            _legal_terminos_y_condiciones: {
                link: "https://www.segofinance.com/legal/terminos-y-condiciones"
            },
            _legal_politica_de_privacidad: {
                link: "https://www.segofinance.com/legal/politica-de-privacidad"
            },
        }
    data =
        {
            _: {
                title: "Inversión alternativa | Oportunidades de inversión | SEGO",
                metaTags: [
                    { name: "title", content: "Inversión alternativa | Oportunidades de inversión | SEGO" },
                    { name: "description", content: "Encuentra las mejores oportunidades de inversión en SEGO Finance. Contamos con líneas de inversión en startups, factoring y robo advisor ¡Invierte!" },
                    { name: "twitter:card", content: "summary_large_image" },
                    { name: "twitter:title", content: "Inversión alternativa | Oportunidades de inversión | SEGO" },
                    { name: "twitter:description", content: "Encuentra las mejores oportunidades de inversión en SEGO Finance. Contamos con líneas de inversión en startups, factoring y robo advisor ¡Invierte!" },
                    { name: "twitter:image", content: "" },
                    { property: "og:type", content: "website" },
                    { property: "og:url", content: "https://www.segofinance.com" },
                    { property: "og:title", content: "Inversión alternativa | Oportunidades de inversión | SEGO" },
                    { property: "og:description", content: "Encuentra las mejores oportunidades de inversión en SEGO Finance. Contamos con líneas de inversión en startups, factoring y robo advisor ¡Invierte!" },
                    { property: "og:image", content: "" },
                ]
            },
            _quienes_somos: {
                title: "Plataforma de inversión minorista | SEGO Finance",
                metaTags: [
                    { name: "title", content: "Plataforma de inversión minorista | SEGO Finance" },
                    { name: "description", content: "Somos una plataforma de inversión minorista con más de 10 años de experiencia ¡Súmate a la revolución de la banca privada con SEGO!" },
                    { name: "twitter:card", content: "summary_large_image" },
                    { name: "twitter:title", content: "Plataforma de inversión minorista | SEGO Finance" },
                    { name: "twitter:description", content: "Somos una plataforma de inversión minorista con más de 10 años de experiencia ¡Súmate a la revolución de la banca privada con SEGO!" },
                    { name: "twitter:image", content: "" },
                    { property: "og:type", content: "website" },
                    { property: "og:url", content: "https://www.segofinance.com/quienes-somos/" },
                    { property: "og:title", content: "Plataforma de inversión minorista | SEGO Finance" },
                    { property: "og:description", content: "Somos una plataforma de inversión minorista con más de 10 años de experiencia ¡Súmate a la revolución de la banca privada con SEGO!" },
                    { property: "og:image", content: "" },
                ]
            },
        }
}
