<ng-container>
  <!-- Desktop -->
  <div id="button-perfil" class="d-none d-md-block" >
    <button class="btn-perfil bg-transparent px-2" title="Mi perfil" (click)="toggleMenu()" (clickOutside)="hideMenu()">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-center perfil-circle bg-primary-900 primary-50 border-radius-50 me-1">
          @if (isLoading) {
            <app-is-loading [color]="'neutral'" [size]="16" [stroke]="4"></app-is-loading>
          }
          @if (!isLoading && perfilInversor.siglas) {
            <h5 class="mb-0"><strong>{{ perfilInversor.siglas}}</strong></h5>
          }
          @if (!isLoading && !perfilInversor.siglas) {
            <span class="material-symbols-outlined primary-50">person</span>
          }
        </div>
        <span class="primary-900 material-symbols-outlined">
          {{isMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}
        </span>
      </div>
    </button>

    @if (isMenuOpen) {
      <div class="menu-perfil-opened">
        <div id="menu-perfil" class="bg-primary-50 border-radius-4 shadow-8 py-2 fadeinMenu">
          <div class="row">
            <div class="col-12">
              <div class="px-3 perfil-item">
                <div class="d-flex align-items-center text-start h-100 ">
                  <span class="p1-medium mb-0 primary-800"><strong class="p1-bold">Hola {{perfilInversor.nombre}}</strong></span>
                  <span class="p1-medium mb-0 primary-800 px-2">|</span>
                  <a href="javascript:void(0)" class="link1-semibold mb-0 primary-800 text-decoration-underline" (click)="logOut()"
                  title="Salir">Salir</a>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="px-3 perfil-item">
                @if (plataformaId === 'SEGOFINANCE') {
                  <a routerLink="/panel-inversor/info-usuario" class="p1-medium primary-800" title="Mi cuenta">
                    <div class="d-flex align-items-center h-100">
                      Mi cuenta
                    </div>
                  </a>
                }
                @if (plataformaId !== 'SEGOFINANCE') {
                  <a href="{{domainSegoUrl + '/panel-inversor/info-usuario'}}"  class="p1-medium primary-800" title="Mi cuenta">
                    <div class="d-flex align-items-center h-100">
                      Mi cuenta
                    </div>
                  </a>
                }
              </div>
            </div>
            <div class="col-12">
              <div class="px-3 perfil-item">
                @if (plataformaId === 'SEGOFINANCE') {
                  <a  routerLink="/panel-inversor/posicion-global" class="p1-medium primary-800" title="Posicion Global">
                    <div class="d-flex align-items-center h-100">
                      Posicion Global
                    </div>
                  </a>
                }
                @if (plataformaId !== 'SEGOFINANCE') {
                  <a href="{{domainSegoUrl + '/panel-inversor/posicion-global'}}"  class="p1-medium primary-800" title="Posicion Global">
                    <div class="d-flex align-items-center h-100">
                      Posición Global
                    </div>
                  </a>
                }
              </div>
            </div>
            <div class="col-12">
              <div class="px-3 perfil-item">
                @if (plataformaId === 'SEGOFINANCE') {
                  <a routerLink="/panel-inversor/info-usuario/wallet" class="p1-medium primary-800" title="Mi wallet">
                    <div class="d-flex align-items-center h-100">
                      Mi wallet
                    </div>
                  </a>
                }
                @if (plataformaId !== 'SEGOFINANCE') {
                  <a href="{{domainSegoUrl + '/panel-inversor/info-usuario/wallet'}}" class="p1-medium primary-800" title="Mi wallet">
                    <div class="d-flex align-items-center h-100">
                      Mi wallet
                    </div>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <!-- Mobile -->
  <div id="button-perfil-mb" class="d-block d-md-none">
    <button class="btn-perfil bg-transparent" title="Mi perfil" (click)="showMenuMobile()">
      <div class="d-flex align-items-center justify-content-center perfil-circle bg-primary-900 primary-50 border-radius-50">
        <h5 class="mb-0">
          <strong>{{perfilInversor.siglas}}</strong>
        </h5>
      </div>
    </button>
  </div>
</ng-container>


