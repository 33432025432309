<div id="menu-acceso-mb" class="bg-primary-50 border-radius-4 shadow-8 py-2 px-3 fadeinMenu">
  <div class="row gx-0">
    <div class="col-12">
      <div class="acceso-item">
        <div class="d-flex align-items-center h-100">
          <strong class="p1-bold primary-900 mb-0">SEGOFINANCE</strong>
          <span role="button" class="material-symbols-outlined ms-auto ps-1" (click)="closeMenu()">close</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="linea-separadora-1 border-primary-100"></div>
    </div>

    <div class="col-6 my-3 pe-2">
      <a class="btn btn-primary btn-acceso-mb border-2 d-flex align-items-center justify-content-center" routerLink="/login" [queryParams]="{returnUrl: returnUrlParam}" title="Acceder" role="button" (click)="closeMenu()">
        Acceder
      </a>
    </div>

    <div class="col-6 my-3 ps-2">
      <a class="btn btn-secondary btn-acceso-mb border-2 d-flex align-items-center justify-content-center" routerLink="/registro" [queryParams]="{returnUrl: returnUrlParam}" title="Regístrate" role="button" (click)="closeMenu()">
        Regístrate
      </a>
    </div>

    <div class="col-12">
      <div class="linea-separadora-1 border-primary-100"></div>
    </div>

    <div class="col-12">
      <div class="acceso-item">
        @if (plataformaId === 'SEGOFINANCE') {
          <a routerLink="/panel-inversor/info-usuario"  (click)="closeMenu()" class="p1-medium primary-900" title="Mi cuenta">
            <div class="d-flex align-items-center h-100">
              Mi cuenta
            </div>
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a href="{{domainSegoUrl + '/panel-inversor/info-usuario'}}" (click)="closeMenu()" class="p1-medium primary-900" title="Mi cuenta">
            <div class="d-flex align-items-center h-100">
              Mi cuenta
            </div>
          </a>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="acceso-item">
        @if (plataformaId === 'SEGOFINANCE') {
          <a routerLink="/panel-inversor/posicion-global" (click)="closeMenu()" class="p1-medium primary-900" title="Posición Global">
            <div class="d-flex align-items-center h-100">
              Posición Global
            </div>
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a href="{{domainSegoUrl + '/panel-inversor/posicion-global'}}" (click)="closeMenu()" class="p1-medium primary-900" title="Posición Global">
            <div class="d-flex align-items-center h-100">
              Posición Global
            </div>
          </a>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="acceso-item">
        @if (plataformaId === 'SEGOFINANCE') {
          <a routerLink="/panel-inversor/info-usuario/wallet" (click)="closeMenu()" class="p1-medium primary-900" title="Mi wallet">
            <div class="d-flex align-items-center h-100">
              Mi wallet
            </div>
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a href="{{domainSegoUrl + '/panel-inversor/info-usuario/wallet'}}" (click)="closeMenu()" class="p1-medium primary-900" title="Mi wallet">
            <div class="d-flex align-items-center h-100">
              Mi wallet
            </div>
          </a>
        }
      </div>
    </div>
  </div>
</div>

