import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { Seccion } from 'src/app/header/_models/seccion';
import { MenuMobileComponent } from 'src/app/header/menu-opened/menu-mobile/menu-mobile.component';
import { LogoPlataformaComponent } from 'src/app/header/utils/logo-plataforma/logo-plataforma.component';
import { AccesoUsuarioComponent } from 'src/app/header/utils/acceso-usuario/acceso-usuario.component';
import { PerfilUsuarioComponent } from 'src/app/header/utils/perfil-usuario/perfil-usuario.component';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    LogoPlataformaComponent,
    AccesoUsuarioComponent,
    PerfilUsuarioComponent
  ]
})

export class HeaderMobileComponent implements OnInit {

  @Input() isLogged: boolean;
  @Input() plataforma: string;
  @Input() secciones: Seccion[];

  constructor(private _matDialog: MatDialog) { }

  ngOnInit(): void { }

  openDialogMenu(){
    this._matDialog.open(MenuMobileComponent, 
      {
        panelClass: 'dialog-menu',
        autoFocus: false,
        data: {
          isLogged: this.isLogged,
          secciones: this.secciones
        }
      }
    )
  }
}
