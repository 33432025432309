import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorConfig, SnackBarSuccessConfig } from 'src/app/_utils/_material-import/snackbar-config';
import { FeedbackService } from 'src/app/_services/feedback.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { PlatformService } from 'src/app/_services/platform.service';


@Component({
  selector: 'app-btn-home-floating',
  templateUrl: './btn-home-floating.component.html',
  styleUrls: ['./btn-home-floating.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule
  ]
})


export class BtnHomeFloatingComponent implements OnInit
{

  @Input() isContactarHidden: boolean;
  @Output() isContactarClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  isRutaCnmv: boolean;
  isLogged: boolean;
  isLoading: boolean = false;
  isFeedbackOpen: boolean = false;
  feedbackWebForm: UntypedFormGroup;
  email: string;
  plataformaId: string;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: Object,
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _feedbackService: FeedbackService,
    private _matSnackBar: MatSnackBar,
    private _usuarioService: UsuarioService,
    private _platformService: PlatformService,
  )
  {
    this._router.events.subscribe((event: Event) =>
    {
      if (event instanceof NavigationEnd)
      {
        if (isPlatformBrowser(this._platformId))
        {
          this.checkRuta();
        }
      }
    })
  }

  ngOnInit(): void
  {
    this.checkRuta();
    this.buildForm();
  }


  private checkRuta(): void
  {
    const ruta = this._router.url;

    if (this.plataformaId === 'VENTURE' || this.plataformaId === 'REALESTATE' || this.plataformaId === 'CREATIVE')
    {
      this.isRutaCnmv = true;
    }

    if (!this.isContactarHidden && (ruta.includes("login")) || (ruta.includes("registro")))
    {
      this.isContactarHidden = true;
    } else
    {
      this.isContactarHidden = false;
    }
  }

  private buildForm(): void
  {
    this.isLogged = this._usuarioService.getSession() ? true : false;
    this.email = this._usuarioService.getSession() ? this._usuarioService.getSession().email : null; 
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
    this.feedbackWebForm = this._formBuilder.group(
      {
        isLogged: [this.isLogged, [Validators.required]],
        comentario: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(255)]],
        isAnonimo: [false, [Validators.required]],
        email: [this.email, [Validators.required, Validators.email]],
        plataforma: [this.plataformaId.toLocaleLowerCase(), Validators.required]
      }
    );
  }

  toggleFeedback()
  {
    this.isFeedbackOpen = !this.isFeedbackOpen;
    if (!this.isFeedbackOpen)
    {
      this.buildForm();
    }
  }

  setAnonimo(isAnonimo: boolean)
  {
    if (isAnonimo)
    {
      this.feedbackWebForm.get('email').setValidators([Validators.nullValidator]);
      this.feedbackWebForm.get('email').disable();
    } else
    {
      this.feedbackWebForm.get('email').setValidators([Validators.required, Validators.email]);
      this.feedbackWebForm.get('email').enable();
    }
    this.feedbackWebForm.get('email').updateValueAndValidity();
  }

  sendFeedback()
  {
    this.isLoading = true;

    this.feedbackWebForm.controls['isLogged'].setValue(this.isLogged);
    if (!this.feedbackWebForm.value.isLogged && this.feedbackWebForm.value.isAnonimo)
    {
      this.feedbackWebForm.controls['email'].setValue('no-registrado (anónimo)');
    } else
    {
      this.feedbackWebForm.controls['email'].setValue('no-registrado (' + this.feedbackWebForm.value.email + ')');
    }

    this._feedbackService.enviarFeedback(this.feedbackWebForm.getRawValue()).subscribe({
      next: (r) =>
      {
        this.isLoading = false;
        this.toggleFeedback();
        this._matSnackBar.open("Tu comentario ha sido recibido, muchas gracias", 'x', new SnackBarSuccessConfig());
      },
      error: (e) =>
      {
        this.isLoading = false;
        this.toggleFeedback();
        this._matSnackBar.open("No se ha podido enviar el comentario, vuelve a intentarlo más tarde", 'x', new SnackBarErrorConfig());
      }
    });
  }

  hideContactar()
  {
    this.isContactarHidden = true;
    this.isContactarClosed.emit();
  }
}
