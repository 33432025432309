import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { SnackBarErrorConfig } from 'src/app/_utils/_material-import/snackbar-config';
import { PerfilInversor } from 'src/app/header/_models/perfil-inversor';
import { PerfilUsuarioMobileComponent } from 'src/app/header/utils/perfil-usuario-mobile/perfil-usuario-mobile.component';
import { InformacionBasicaService } from 'src/app/panel-inversor/_services/informacion-basica.service';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    NgClickOutsideDirective,
    RouterModule
  ]
})

export class PerfilUsuarioComponent implements OnInit {
  @Input() isSeccionOpened: string;
  @Output() perfilOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  isLoading: boolean = false;
  isMenuOpen: boolean = false;
  perfilInversor: PerfilInversor = new PerfilInversor();
  plataformaId: string;
  domainSegoUrl: string;

  constructor(
    private _matSnackBar: MatSnackBar,
    private _matBottomSheetRef: MatBottomSheet,
    private _informacionBasicaService: InformacionBasicaService,
    private _usuarioService: UsuarioService,
    private _platformService: PlatformService
  ) { 
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
    this.domainSegoUrl = this._platformService.getSubdominioUrl('segofinance');
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Cierra el menu de perfil si se abre alguna sección del menu (desktop)
    if(changes.isSeccionOpened.currentValue && this.isSeccionOpened){
      this.hideMenu();
    } 
  }

  ngOnInit(): void {
    this.getSiglasInversor();
    this.getNombreInversor();
  }
  

  private getSiglasInversor(): void 
  {
    this.isLoading = true;
    this.perfilInversor.siglas = this._usuarioService.getSession().siglas;
    this.isLoading = false;
  }

  private getNombreInversor(): void{
    this.isLoading = false;
    this._informacionBasicaService.get().subscribe({
      next: (r) => {
        this.isLoading = false;
        this.perfilInversor.nombre = r.nombre;
        this.perfilInversor.apellidos = r.apellidos;
      },
      error: (e) => {
        this.isLoading = false;
        this._usuarioService.getSession() && this.logOut();
        let errorMsg = this._informacionBasicaService.getServerErrors(e);
        this._matSnackBar.open(`${errorMsg}`, 'x', new SnackBarErrorConfig());
      }
    });
  }

  showMenu(): void {
    this.isMenuOpen = true;
    this.perfilOpened.emit(true);
  }

  hideMenu(): void {
    this.isMenuOpen = false;
  } 

  toggleMenu(): void {
    if(this.isMenuOpen === true){
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  showMenuMobile(): void {
    this._matBottomSheetRef.open(PerfilUsuarioMobileComponent, {
      autoFocus: false, 
      data: {
        nombreInversor: this.perfilInversor.nombre + " " + this.perfilInversor.apellidos
      }
    });
  }
  
  logOut(): void
  {
    this._usuarioService.logOut(true);
  }
}
