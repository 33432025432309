@if (tipoFooter !== 'password') {
  <app-riesgos-blast></app-riesgos-blast>
}
<div class="container-fluid bg-primary-800 primary-50" [ngClass]="{'py-5': tipoFooter === 'password'}">
  <div class="text-center px-4 py-4">
    <p class="t3-xs primary-100 mb-0">SociosInversores 2010 PFP S.L. es una empresa del Grupo SEGOFINANCE
      supervisada por la
      <a href="https://www.cnmv.es/portal/Consultas/Servicios-Financiacion-Participativa/Listado-Proveedores.aspx"
        target="_blank" class="text-decoration-underline t3-xs primary-100"
        title="Comisión Nacional del Mercado de Valores (CNMV)">
        Comisión Nacional del Mercado de Valores (CNMV)
      </a>
    </p>
  </div>
</div>