<div id="header-mobile" class="row align-items-center">
  <div class="col-2 ps-0 text-start">
    <div class="d-flex align-items-center btn-menu">
      <span class="material-symbols-outlined icon-menu" role="button" (click)="openDialogMenu()">menu</span>
    </div>
  </div>
  <div class="col-8">
    <app-logo-plataforma [plataforma]="plataforma"></app-logo-plataforma>
  </div>
  <div class="col-2 pe-0 text-end">
    @if (!isLogged) {
      <app-acceso-usuario></app-acceso-usuario>
    }
    @if (isLogged) {
      <app-perfil-usuario></app-perfil-usuario>
    }
  </div>
</div>