<div id="wallet-bar" class="bg-primary-300 border-top-1 border-primary-300 px-3 px-lg-4">
  <div class="row align-items-center wallet-height">
    <div class="col-5 col-sm-6">
      @if (isLoading && !perfilInversor) {
        <app-is-loading [color]="'segofinance'" [size]="16" [stroke]="2" [align]="'start'"></app-is-loading>
      }
      @if (!isLoading && perfilInversor) {
        @if (plataformaId === 'SEGOFINANCE') {
          <a routerLink="/panel-inversor/info-usuario/informacion-basica" class="p2-regular primary-900">
            @if (perfilInversor.nombre) {
              <strong class="p2-bold" class="d-block d-md-none">Hola {{perfilInversor.nombre}}</strong>
            }
            @if (perfilInversor.nombre && perfilInversor.apellidos) {
              <strong class="p2-bold" class="d-none d-md-block">Hola {{perfilInversor.nombre}} {{perfilInversor.apellidos}}</strong>
            }
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a href="{{domainSegoUrl + '/panel-inversor/info-usuario/informacion-basica'}}" class="p2-regular primary-900">
            @if (isLoading) {
              <app-is-loading [type]="'text'" [size]="14"></app-is-loading>
            }
            @if (perfilInversor.nombre) {
              <strong class="p2-bold" class="d-block d-md-none">Hola {{perfilInversor.nombre}}</strong>
            }
            @if (perfilInversor.nombre && perfilInversor.apellidos) {
              <strong class="p2-bold" class="d-none d-md-block">Hola {{perfilInversor.nombre}} {{perfilInversor.apellidos}}</strong>
            }
          </a>
        }
      }
    </div>
    <div class="col-7 col-sm-6 pe-1 pe-lg-2">
      @if (plataformaId === 'SEGOFINANCE') {
        <a routerLink="/panel-inversor/info-usuario/wallet"
          class="d-flex align-items-center justify-content-end p2-regular primary-900 mb-0" title="Ver Wallet">
          <strong class="p2-bold me-2 mb-0">Wallet:</strong>
          <app-saldo-wallet></app-saldo-wallet>
          <span class="material-symbols-outlined mat-icon-size-28 rotate-270 primary-900">arrow_drop_down</span>
        </a>
      }
      @if (plataformaId !== 'SEGOFINANCE') {
        <a href="{{domainSegoUrl + '/panel-inversor/info-usuario/wallet'}}"
          class="d-flex align-items-center justify-content-end p2-regular primary-900 mb-0" title="Ver Wallet">
          <strong class="p2-bold me-2 mb-0">Wallet:</strong>
          <app-saldo-wallet></app-saldo-wallet>
          <span class="material-symbols-outlined mat-icon-size-28 rotate-270 primary-900">arrow_drop_down</span>
        </a>
      }
    </div>
  </div>
</div>