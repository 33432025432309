
<div id="navbar-social" class="d-flex align-items-center px-4 h-100">
  <span class="p3-regular mb-0 primary-50">¿Quieres sacar partido a tus inversiones? Contacta con nosotros en
    <a href="mailto:info@segofinance.com" target="_blank" class="link3-semibold primary-50" title="info@segofinance.com">
      info&#64;segofinance.com
    </a>
  </span>
  <div class="d-flex align-items-center ms-auto">
    <span class="p3-regular mb-0 primary-50 pe-2">O síguenos en</span>
    @for (redSocial of redesSociales; track redSocial) {
      <a href="{{redSocial.link}}" target="_blank">
        <img class="icon-social primary-50 mx-1" src="{{redSocial.icono}}" alt="{{redSocial.nombre}}" title="{{redSocial.nombre}}">
      </a>
    }
  </div>
</div>
