<div id="menu-perfil-mb" class="bg-primary-50 border-radius-4 shadow-8 py-2 px-3 fadeinMenu">
  <div class="row gx-0">
    <div class="col-12">
      <div class="perfil-item">
        <div class="d-flex align-items-center h-100">
          @if (data.nombreInversor) {
            <strong class="primary-900 p1-bold mb-0">{{data.nombreInversor | maxCharacters:30}}</strong>
          }
          <span role="button" class="material-symbols-outlined ms-auto ps-1" (click)="closeMenu()">close</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="linea-separadora-1 border-primary-100"></div>
    </div>

    <div class="col-12">
      <div class="perfil-item">
        @if (plataformaId === 'SEGOFINANCE') {
          <a  routerLink="/panel-inversor/info-usuario" (click)="closeMenu()" class="p1-medium primary-900" title="Mi cuenta">
            <div class="d-flex align-items-center h-100">
              Mi cuenta
            </div>
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a  href="{{domainSegoUrl + '/panel-inversor/info-usuario'}}"  (click)="closeMenu()" class="p1-medium primary-900" title="Mi cuenta">
            <div class="d-flex align-items-center h-100">
              Mi cuenta
            </div>
          </a>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="perfil-item">
        @if (plataformaId === 'SEGOFINANCE') {
          <a routerLink="/panel-inversor/posicion-global" (click)="closeMenu()" class="p1-medium primary-900" title="Posicion Global">
            <div class="d-flex align-items-center h-100">
              Posición Global
            </div>
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a href="{{domainSegoUrl + '/panel-inversor/posicion-global'}}"  (click)="closeMenu()" class="p1-medium primary-900" title="Posicion Global">
            <div class="d-flex align-items-center h-100">
              Posición Global
            </div>
          </a>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="perfil-item">
        @if (plataformaId === 'SEGOFINANCE') {
          <a routerLink="/panel-inversor/info-usuario/wallet" (click)="closeMenu()" class="p1-medium primary-900" title="Mi wallet">
            <div class="d-flex align-items-center h-100">
              Mi wallet
            </div>
          </a>
        }
        @if (plataformaId !== 'SEGOFINANCE') {
          <a href="{{domainSegoUrl + '/panel-inversor/info-usuario/wallet'}}" (click)="closeMenu()" class="p1-medium primary-900" title="Mi wallet">
            <div class="d-flex align-items-center h-100">
              Mi wallet
            </div>
          </a>
        }
      </div>
    </div>
    <div class="col-12">
      <div class="perfil-item">
        <a href="javascript:void(0)" (click)="logOut()" role="button" class="p1-medium primary-900" title="Salir">
          <div class="d-flex align-items-center h-100">
            Salir
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
