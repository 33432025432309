import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';

@Component({
  selector: 'app-riesgos-blast',
  templateUrl: './riesgos-blast.component.html',
  styleUrls: ['./riesgos-blast.component.scss'],
  standalone: true,
  imports:[
    SharedModule,
    RouterModule
  ]
})

export class RiesgosBlastComponent 
{

}
