import { Routes } from "@angular/router";
import { AuthGuard } from "src/app/_utils/auth.guard";
import { environment } from "src/environments/environment";
import { formatDate } from '@angular/common';

//#region RoutesPlataformas
const routesGlobales: Routes = [
    {
        path: 'registro',
        loadChildren: () => import('./registro/registro.routes').then(r => r.RegistroRoutes),
    },
    {
        path: 'codigo-activacion',
        loadComponent: () => import('./registro/codigo-activacion/codigo-activacion.component').then(c => c.CodigoActivacionComponent),
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.routes').then(r => r.LoginRoutes),
    },
    {
        path: 'olvido-password',
        loadComponent: () => import('./login/olvido-password/olvido-password.component').then(c => c.OlvidoPasswordComponent),
    },
    {
        path: 'olvido-password/exito',
        loadComponent: () => import('./_shared/bloque-exito/bloque-exito.component').then(c => c.BloqueExitoComponent),
    },
    {
        path: 'casos-exito',
        loadChildren: () => import('./casos-exito/casos-exito.routes').then(r => r.CasosExitoRoutes)
    },
    {
        path: 'preguntas-frecuentes',
        loadChildren: () => import('./preguntas-frecuentes/preguntas-frecuentes.routes').then(r => r.PreguntasFrecuentesRoutes)
    },
    {
        path: 'legal',
        loadChildren: () => import('./footer/footer.routes').then(r => r.FooterRoutes)
    },
    {
        path: 'helpdesk',
        loadChildren: () => import('./helpdesk/helpdesk.routes').then(r => r.HelpdeskRoutes)
    },
    {
        path: 'landings',
        loadChildren: () => import('./landings/landings.routes').then(r => r.LandingsRoutes)
    },
    {
        path: 'error',
        loadChildren: () => import('./_shared/bloque-feedback/error/error.routes').then(r => r.ErrorRoutes)
    },
    {
        path: 'problemas-tecnicos',
        loadComponent: () => import('./_shared/bloque-avisos/problemas-tecnicos/problemas-tecnicos.component').then(c => c.ProblemasTecnicosComponent),
    },
    {
        path: 'wallet/bloqueo-payout',
        loadComponent: () => import('./bloqueos/pay-out/pay-out.component').then(c => c.PayOutComponent),
    },
    {
        path: 'wallet/bloqueo-payin',
        loadComponent: () => import('./bloqueos/pay-in/pay-in.component').then(c => c.PayInComponent),
    },
]

const routesSegoFinance: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.routes').then(r => r.HomeRoutes)
    },
    {
        path: 'panel-inversor',
        loadChildren: () => import('src/app/panel-inversor/panel-inversor.routes').then(r => r.PanelInversorRoutes),
        canActivate: [AuthGuard]
    },
    {
        path: 'quienes-somos',
        loadChildren: () => import('./quienes-somos/quienes-somos.routes').then(r => r.QuienesSomosRoutes)
    },
    {
        path: 'creative',
        loadComponent: () => import('./creative/public/audiovisual/landing-index/landing-index.component').then(c => c.LandingIndexComponent)
    },
    {
        path: 'info-creative',
        loadComponent: () => import('./creative/public/audiovisual/landing-creative-info/landing-creative-info.component').then(c => c.LandingCreativeInfoComponent)
    },
    {
        path: 'creative/registro',
        loadComponent: () => import('./creative/public/audiovisual/landing-registro/landing-registro.component').then(c => c.LandingRegistroComponent)
    },
    {
        path: 'creative/registro/confirmacion',
        loadComponent: () => import('./creative/public/audiovisual/landing-feedback/landing-feedback.component').then(c => c.LandingFeedbackComponent)
    },
    {
        path: 'creative/registro/error',
        loadComponent: () => import('./creative/public/audiovisual/landing-feedback/landing-feedback.component').then(c => c.LandingFeedbackComponent)
    },
    {
        path: '**', redirectTo: '/', pathMatch: 'prefix'
    },
]

const routesVenture: Routes = [
    {
        path: '',
        loadChildren: () => import('./venture/venture.routes').then(r => r.VentureRoutes),
    },
    {
        path: 'blast',
        loadChildren: () => import('./blast/blast.routes').then(r => r.BlastRoutes)
    },
    {
        path: '**', redirectTo: '/', pathMatch: 'prefix'
    },
]

const routesRealEstate: Routes = [
    {
        path: '',
        loadChildren: () => import('./realestate/realestate.routes').then(r => r.RealEstateRoutes),
    },
    {
        path: '**', redirectTo: '/', pathMatch: 'prefix'
    },
]

const routesFactoring: Routes = [
    {
        path: '',
        loadChildren: () => import('./factoring/factoring.routes').then(r => r.FactoringRoutes),
    },
    {
        path: '**', redirectTo: '/', pathMatch: 'prefix'
    },
]

const routesFunds: Routes = [
    {
        path: '',
        loadChildren: () => import('./funds/funds.routes').then(r => r.FundsRoutes),
    },
    {
        path: '**', redirectTo: '/', pathMatch: 'prefix'
    },
]

const routesCreative: Routes = [
    {
        path: '',
        loadChildren: () => import('./creative/creative.routes').then(r => r.CreativeRoutes),
    },
    {
        path: '**', redirectTo: '/', pathMatch: 'prefix'
    },
]

//#endregion

const routesMantenimiento: Routes = [
    {
        path: '', redirectTo: '/mantenimiento', pathMatch: 'prefix'
    },
    {
        path: 'mantenimiento',
        loadComponent: () => import('./landings/landing-mantenimiento/landing-mantenimiento.component').then(c => c.LandingMantenimientoComponent)
    },
    {
        path: '**', redirectTo: '/mantenimiento', pathMatch: 'prefix'
    },
]

//Comprobación fecha de mantenimiento para establecer routes
const today: Date = new Date();
const fechaMantenimiento: Date = new Date('1970-01-01');
const isMantenimiento: boolean = (formatDate(today, 'dd/MM/yyyy', 'en-US')) ===  (formatDate(fechaMantenimiento, 'dd/MM/yyyy', 'en-US'));

export const routes: Routes = isMantenimiento ? routesMantenimiento : routesGlobales;
if(!isMantenimiento)
switch (environment.linea) {
    case 'venture':
        routes.push(...routesVenture);
        break;
    case 'realestate':
        routes.push(...routesRealEstate);
        break;
    case 'factoring':
        routes.push(...routesFactoring);
        break;
    case 'funds':
        routes.push(...routesFunds);
        break;
    case 'creative':
        routes.push(...routesCreative);
        break;
    case 'segofinance':
    default:
        routes.push(...routesSegoFinance);
        break;
}