import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { RouterModule } from '@angular/router';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { DataPerfil } from 'src/app/header/_models/data-perfil';

@Component({
  selector: 'app-perfil-usuario-mobile',
  templateUrl: './perfil-usuario-mobile.component.html',
  styleUrls: ['./perfil-usuario-mobile.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})

export class PerfilUsuarioMobileComponent {

  plataformaId: string;
  domainSegoUrl: string;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DataPerfil,
    private _perfilBottomRef: MatBottomSheetRef<PerfilUsuarioMobileComponent>,
    private _usuarioService: UsuarioService,
    private _platformService: PlatformService
  ) { 
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment];
    this.domainSegoUrl = this._platformService.getSubdominioUrl('segofinance');
  }

  logOut(): void
  {
    this._usuarioService.logOut(true);
  }

  closeMenu(): void {
    this._perfilBottomRef.dismiss();
  }
}
