import { Component } from '@angular/core';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { LogoPlataformaComponent } from 'src/app/header/utils/logo-plataforma/logo-plataforma.component';

@Component({
  selector: 'app-cabecera-plataforma-erronea',
  standalone: true,
  imports: [
    LogoPlataformaComponent
  ],
  templateUrl: './cabecera-plataforma-erronea.component.html',
  styleUrl: './cabecera-plataforma-erronea.component.scss'
})
export class CabeceraPlataformaErroneaComponent
{
  plataforma: string;

  constructor(
    private _platformService: PlatformService
  ){
    this.plataforma = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment].toLowerCase();
  }
}
