import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Seccion } from 'src/app/header/_models/seccion';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { Subseccion } from 'src/app/header/_models/subseccion';
import { DOMAIN_ENVIRONMENT } from 'src/app/_services/base.service';
import { PlatformService } from 'src/app/_services/platform.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { NavbarSocialComponent } from 'src/app/header/utils/navbar-social/navbar-social.component';
import { WalletBarComponent } from 'src/app/_shared/bloque-wallet-nuevo/wallet-bar/wallet-bar.component';
import { HeaderDesktopComponent } from 'src/app/header/header-desktop/header-desktop.component';
import { HeaderTabletComponent } from 'src/app/header/header-tablet/header-tablet.component';
import { HeaderMobileComponent } from 'src/app/header/header-mobile/header-mobile.component';
import { SubseccionLink } from 'src/app/header/_models/subseccion-link';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    NavbarSocialComponent,
    HeaderDesktopComponent,
    HeaderTabletComponent,
    HeaderMobileComponent,
    WalletBarComponent,
  ]
})
export class HeaderComponent implements OnInit
{

  isLogged: boolean;
  plataformaId: string;
  secciones: Seccion[];

  constructor(private _router: Router, private _usuarioService: UsuarioService, private _platformService: PlatformService) { }

  ngOnInit(): void
  {
    this.getSession();
    this.getPlataforma();
    this.onRouterNavigation();
  }

  private onRouterNavigation(): void
  {
    this._router.events.subscribe((event: Event) =>
    {
      if (event instanceof NavigationEnd)
      {
        this.getSession();
        this.getPlataforma();
      }
    });
  }

  private getSession(): void
  {
    this.isLogged = this._usuarioService.getSession() ? true : false;
  }

  private getPlataforma(): void
  {
    this.plataformaId = DOMAIN_ENVIRONMENT[this._platformService._domainEnvironment].toLowerCase();
    this.getSecciones(this.plataformaId);
  }


  //TODO: Refactorizar toda la carga de los elementos del menu y los tipos de links 
  private getSecciones(plataformaId: string)
  {
    // #region Grupo SEGOFINANCE
    if (plataformaId === "segofinance")
    {
      this.secciones = [
        {
          id: 'financiacion-participativa',
          nombre: 'Financiación Participativa',
          icon: 'workspaces',
          col: 'col-4 text-center',
          subsecciones: [
            {
              id: 'venture',
              icon: 'moving',
              lineaColor: 'bg-secondary-venture-500',
              nombre: 'SEGO <span class="secondary-venture-500">Venture</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Startups</strong>',
              route: this._platformService.getSubdominioUrl('venture'),
              linkType: 'href',
            },
            {
              id: 'realestate',
              icon: 'maps_home_work',
              lineaColor: 'bg-secondary-estate-500',
              nombre: 'SEGO <span class="menu-linea secondary-estate-500">Real Estate</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Inmobiliario</strong>',
              route: this._platformService.getSubdominioUrl('realestate'),
              linkType: 'href',
            },
            {
              id: 'creative',
              icon: 'lightbulb',
              lineaColor: 'bg-secondary-creative-500',
              nombre: 'SEGO <span class="menu-linea secondary-creative-500">Creative</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Cultura</strong>',
              route: this._platformService.getSubdominioUrl('creative'),
              linkType: 'href',
            },
          ]
        },
        {
          id: 'otros-productos',
          nombre: 'Otros Productos',
          icon: 'explore',
          col: 'col-3 text-center',
          subsecciones: [
            {
              id: 'factoring',
              icon: 'insert_drive_file',
              lineaColor: 'bg-secondary-factoring-600',
              nombre: 'SEGO <span class="menu-linea secondary-factoring-500">Factoring</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Factoring</strong>',
              route: this._platformService.getSubdominioUrl('factoring'),
              linkType: 'href',
            },
            {
              id: 'funds',
              icon: 'savings',
              lineaColor: 'bg-secondary-funds-500',
              nombre: 'SEGO <span class="menu-linea secondary-funds-500">Funds</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Indexados</strong>',
              route: this._platformService.getSubdominioUrl('funds'),
              linkType: 'href',
            }
          ]
        },
        {
          id: 'sobre-nosotros',
          nombre: 'Sobre nosotros',
          icon: 'people_alt',
          col: 'col-3 text-center',
          subsecciones: this.getSubsecciones("sobre-nosotros", plataformaId)
        },
        {
          id: 'contactanos',
          nombre: 'Contáctanos',
          icon: 'info',
          col: 'col-2 text-center',
          subsecciones: this.getSubsecciones("contactanos", plataformaId)
        },
      ]
    }
    //#endregion
    // #region SEGO Venture
    else if (plataformaId === "venture")
    {
      this.secciones = [
        {
          id: 'financiacion-participativa',
          nombre: 'Financiación Participativa',
          icon: 'workspaces',
          col: 'col-4 text-center',
          subsecciones: [
            {
              id: 'venture',
              icon: 'moving',
              lineaColor: 'bg-secondary-venture-500',
              nombre: 'SEGO <span class="menu-linea secondary-venture-500">Venture</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Startups</strong>',
              route: '/panel-inversor',
              linkType: 'routerLink',
              links: [
                { nombre: "Cómo funciona", route: "/como-funciona" },
                { nombre: "Oportunidades de inversión", route: "/oportunidades-inversion" },
                { nombre: "¿Buscas financiación?", route: "/buscas-financiacion" }
              ]
            },
            {
              id: 'realestate',
              icon: 'maps_home_work',
              lineaColor: 'bg-secondary-estate-500',
              nombre: 'SEGO <span class="menu-linea secondary-estate-500">Real Estate</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Inmobiliario</strong>',
              route: this._platformService.getSubdominioUrl('realestate') + '/panel-inversor',
              linkType: 'href',
              links: [
                { nombre: "Cómo funciona", route: this._platformService.getSubdominioUrl('realestate') + "/como-funciona" },
                { nombre: "Oportunidades de inversión", route: this._platformService.getSubdominioUrl('realestate') + "/oportunidades-inversion" },
                { nombre: "¿Buscas financiación?", route: this._platformService.getSubdominioUrl('realestate') + "/buscas-financiacion" }
              ]
            },
            {
              id: 'creative',
              icon: 'lightbulb',
              lineaColor: 'bg-secondary-creative-500',
              nombre: 'SEGO <span class="menu-linea secondary-creative-500">Creative</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Cultura</strong>',
              route: this._platformService.getSubdominioUrl('creative') + '/panel-inversor',
              linkType: 'href',
              links: [
                //{ nombre: "Cómo funciona", route: this._platformService.getSubdominioUrl('creative') + "/como-funciona" },
                { nombre: "Oportunidades audiovisuales", route: this._platformService.getSubdominioUrl('creative') + "/audiovisuales" },
                // { nombre: "Oportunidades videojuegos", route: this._platformService.getSubdominioUrl('creative') + "/videojuegos" },
                { nombre: "¿Buscas financiación?", route: this._platformService.getSubdominioUrl('creative') + "/buscas-financiacion" }
              ]
            },
          ]
        },
        {
          id: 'casos-exito',
          nombre: 'Casos de éxito',
          icon: 'verified',
          col: 'col-3 text-center',
          route: '/casos-exito',
        },
        {
          id: 'preguntas-frecuentes',
          nombre: 'Preguntas Frecuentes',
          icon: 'help_outline',
          col: 'col-3 text-center',
          route: '/preguntas-frecuentes',
        },
        {
          id: 'contactanos',
          nombre: 'Contáctanos',
          icon: 'info',
          col: 'col-2 text-center',
          subsecciones: this.getSubsecciones("contactanos", plataformaId)
        },
      ]
      //#endregion
      // #region SEGO REALESTATE
    } else if (plataformaId === "realestate")
    {
      this.secciones = [
        {
          id: 'financiacion-participativa',
          nombre: 'Financiación Participativa',
          icon: 'workspaces',
          col: 'col-4 text-center',
          subsecciones: [
            {
              id: 'venture',
              icon: 'moving',
              lineaColor: 'bg-secondary-venture-500',
              nombre: 'SEGO <span class="menu-linea secondary-venture-500">Venture</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Startups</strong>',
              route: this._platformService.getSubdominioUrl('venture') + '/panel-inversor',
              linkType: 'href',
              links: [
                { nombre: "Cómo funciona", route: this._platformService.getSubdominioUrl('venture') + "/como-funciona" },
                { nombre: "Oportunidades de inversión", route: this._platformService.getSubdominioUrl('venture') + "/oportunidades-inversion" },
                { nombre: "¿Buscas financiación?", route: this._platformService.getSubdominioUrl('venture') + "/buscas-financiacion" }
              ]
            },
            {
              id: 'realestate',
              icon: 'maps_home_work',
              lineaColor: 'bg-secondary-estate-500',
              nombre: 'SEGO <span class="menu-linea secondary-estate-500">Real Estate</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Inmobiliario</strong>',
              route: '/panel-inversor',
              linkType: 'routerLink',
              links: [
                { nombre: "Cómo funciona", route: "/como-funciona" },
                { nombre: "Oportunidades de inversión", route: "/oportunidades-inversion" },
                { nombre: "¿Buscas financiación?", route: "/buscas-financiacion" }
              ]
            },
            {
              id: 'creative',
              icon: 'lightbulb',
              lineaColor: 'bg-secondary-creative-500',
              nombre: 'SEGO <span class="menu-linea secondary-creative-500">Creative</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Cultura</strong>',
              route: this._platformService.getSubdominioUrl('creative') + '/panel-inversor',
              linkType: 'href',
              links: [
                //{ nombre: "Cómo funciona", route: this._platformService.getSubdominioUrl('creative') + "/como-funciona" },
                { nombre: "Oportunidades audiovisuales", route: this._platformService.getSubdominioUrl('creative') + "/audiovisuales" },
                // { nombre: "Oportunidades videojuegos", route: this._platformService.getSubdominioUrl('creative') + "/videojuegos" },
                { nombre: "¿Buscas financiación?", route: this._platformService.getSubdominioUrl('creative') + "/buscas-financiacion" }
              ]
            },
          ]
        },
        {
          id: 'casos-exito',
          nombre: 'Casos de éxito',
          icon: 'verified',
          col: 'col-3 text-center',
          route: '/casos-exito',
        },
        {
          id: 'preguntas-frecuentes',
          nombre: 'Preguntas Frecuentes',
          icon: 'help_outline',
          col: 'col-3 text-center',
          route: '/preguntas-frecuentes',
        },
        {
          id: 'contactanos',
          nombre: 'Contáctanos',
          icon: 'info',
          col: 'col-2 text-center',
          subsecciones: this.getSubsecciones("contactanos", plataformaId)
        },
      ]
    }
    //#endregion
    // #region SEGO CREATIVE
    else if (plataformaId === "creative")
    {
      this.secciones = [
        {
          id: 'financiacion-participativa',
          nombre: 'Financiación Participativa',
          icon: 'workspaces',
          col: 'col-4 text-center',
          subsecciones: [
            {
              id: 'venture',
              icon: 'moving',
              lineaColor: 'bg-secondary-venture-500',
              nombre: 'SEGO <span class="menu-linea secondary-venture-500">Venture</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Startups</strong>',
              route: this._platformService.getSubdominioUrl('venture') + '/panel-inversor',
              linkType: 'href',
              links: [
                { nombre: "Cómo funciona", route: this._platformService.getSubdominioUrl('venture') + "/como-funciona" },
                { nombre: "Oportunidades de inversión", route: this._platformService.getSubdominioUrl('venture') + "/oportunidades-inversion" },
                { nombre: "¿Buscas financiación?", route: this._platformService.getSubdominioUrl('venture') + "/buscas-financiacion" }
              ]
            },
            {
              id: 'realestate',
              icon: 'maps_home_work',
              lineaColor: 'bg-secondary-estate-500',
              nombre: 'SEGO <span class="menu-linea secondary-estate-500">Real Estate</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Inmobiliario</strong>',
              route: this._platformService.getSubdominioUrl('realestate') + '/panel-inversor',
              linkType: 'href',
              links: [
                { nombre: "Cómo funciona", route: this._platformService.getSubdominioUrl('realestate') + "/como-funciona" },
                { nombre: "Oportunidades de inversión", route: this._platformService.getSubdominioUrl('realestate') + "/oportunidades-inversion" },
                { nombre: "¿Buscas financiación?", route: this._platformService.getSubdominioUrl('realestate') + "/buscas-financiacion" }
              ]
            },
            {
              id: 'creative',
              icon: 'lightbulb',
              lineaColor: 'bg-secondary-creative-500',
              nombre: 'SEGO <span class="menu-linea secondary-creative-500">Creative</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Cultura</strong>',
              route: '/panel-inversor',
              linkType: 'routerLink',
              links: [
                //{ nombre: "Cómo funciona", route: "/como-funciona" },
                { nombre: "Oportunidades audiovisuales", route: "/audiovisuales" },
                // { nombre: "Oportunidades videojuegos", route: "/videojuegos" },
                { nombre: "¿Buscas financiación?", route: "/buscas-financiacion" }
              ]
            },
          ]
        },
        {
          id: 'casos-exito',
          nombre: 'Casos de éxito',
          icon: 'verified',
          col: 'col-3 text-center',
          route: '/casos-exito',
        },
        {
          id: 'preguntas-frecuentes',
          nombre: 'Preguntas Frecuentes',
          icon: 'help_outline',
          col: 'col-3 text-center',
          route: '/preguntas-frecuentes',
        },
        {
          id: 'contactanos',
          nombre: 'Contáctanos',
          icon: 'info',
          col: 'col-2 text-center',
          subsecciones: this.getSubsecciones("contactanos", plataformaId)
        },
      ]
    }
    //#endregion
    // #region SEGO Factoring
    else if (plataformaId === "factoring")
    {
      this.secciones = [
        {
          id: 'productos',
          nombre: 'Factoring',
          icon: 'workspaces',
          col: 'col-3 text-center',
          subsecciones: [
            {
              id: 'factoring',
              icon: 'insert_drive_file',
              lineaColor: 'bg-secondary-factoring-600',
              nombre: 'SEGO <span class="menu-linea secondary-factoring-500">Factoring</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Factoring</strong>',
              route: '/panel-inversor',
              linkType: 'routerLink',
              links: [
                { nombre: "Operaciones de Inversión", route: "/oportunidades-inversion" },
                { nombre: "Cómo funciona", route: "/como-funciona" },
                { nombre: "Autoinvest", route: "/autoinvest" },
                { nombre: "Calculadora de rentabilidad", route: "/calculadora-rentabilidad" },
                { nombre: "¿Buscas financiación?", route: "/buscas-financiacion" }
              ]
            },
          ]
        },
        {
          id: 'otros-productos-funds-factoring',
          nombre: 'Otros productos',
          icon: 'explore',
          col: 'col-3 text-center',
          subsecciones: [
            {
              id: 'funds',
              icon: 'savings',
              lineaColor: 'bg-secondary-funds-500',
              nombre: 'SEGO <span class="menu-linea secondary-funds-500">Funds</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Indexados</strong>',
              route: '/',
              linkType: 'popup',
            },
            {
              id: 'segofinance',
              lineaColor: 'bg-primary-900',
              nombre: 'SEGOFINANCE',
              route: '/',
              linkType: 'popup',
            }
          ]
        },
        {
          id: 'sobre-nosotros',
          nombre: 'Sobre nosotros',
          icon: 'people_alt',
          col: 'col-3 text-center',
          subsecciones: this.getSubsecciones("sobre-nosotros", plataformaId)
        },
        {
          id: 'contactanos',
          nombre: 'Contáctanos',
          icon: 'info',
          col: 'col-3 text-center',
          subsecciones: this.getSubsecciones("contactanos", plataformaId)
        },
      ]
    }
    //#endregion
    // #region SEGO Funds
    else if (plataformaId === "funds")
    {
      this.secciones = [
        {
          id: 'productos',
          nombre: 'Funds',
          icon: 'workspaces',
          col: 'col-3 text-center',
          subsecciones: [
            {
              id: 'funds',
              icon: 'savings',
              lineaColor: 'bg-secondary-funds-500',
              nombre: 'SEGO <span class="menu-linea secondary-funds-500">Funds</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Indexados</strong>',
              route: '/panel-inversor',
              linkType: 'routerLink',
              links: [
                { nombre: "Cuenta remunerada", route: "/cuentas-remuneradas", isNew: false },
                { nombre: "Depósitos", route: "/depositos", isNew: false },
                { nombre: "Carteras Indexadas", route: "/indexadas", isNew: false },
                { nombre: "Carteras Temáticas", route: "/tematicas", isNew: false },
                { nombre: "Invierte en criptos", route: "/criptomonedas", isNew: true },
              ],
            }
          ]
        },
        {
          id: 'otros-productos-funds-factoring',
          nombre: 'Otros productos',
          icon: 'explore',
          col: 'col-3 text-center',
          subsecciones: [
            {
              id: 'factoring',
              icon: 'insert_drive_file',
              lineaColor: 'bg-secondary-factoring-600',
              nombre: 'SEGO <span class="menu-linea secondary-factoring-500">Factoring</span>',
              subtitulo: 'Invierte en <strong class="p3-semibold">Factoring</strong>',
              route: '/',
              linkType: 'popup',
            },
            {
              id: 'segofinance',
              lineaColor: 'bg-primary-900',
              nombre: 'Grupo SEGOFINANCE',
              route: '/',
              linkType: 'popup',
            }
          ]
        },
        {
          id: 'sobre-nosotros',
          nombre: 'Sobre nosotros',
          icon: 'people_alt',
          col: 'col-3 text-center',
          subsecciones: this.getSubsecciones("sobre-nosotros", plataformaId)
        },
        {
          id: 'contactanos',
          nombre: 'Contáctanos',
          icon: 'info',
          col: 'col-3 text-center',
          subsecciones: this.getSubsecciones("contactanos", plataformaId)
        },
      ]
    }
    //#endregion
  }

  private getSubsecciones(nombre: string, plataforma: string)
  {
    let result: Subseccion[];
    switch (nombre)
    {
      case 'sobre-nosotros':
        let linksSegofinance: SubseccionLink[] = [
          { nombre: "Casos de éxito", route: "/casos-exito" },
          { nombre: "Preguntas frecuentes", route: "/preguntas-frecuentes" },
          { nombre: "¿Quiénes somos?", route: "/quienes-somos" }
        ]
        let linksPlataformas: SubseccionLink[] = [
          { nombre: "Casos de éxito", route: "/casos-exito" },
          { nombre: "Preguntas frecuentes", route: "/preguntas-frecuentes" }
        ]
        result = [
          {
            id: 'acerca-nosotros',
            icon: 'people_outline',
            nombre: '<span class="primary-400">Sobre nosotros</span>',
            links: (plataforma === 'segofinance') ? linksSegofinance : linksPlataformas
          }
        ];
        return result;
      case 'contactanos':
        result = [
          {
            id: 'mas-informacion',
            icon: 'info',
            nombre: '<span class="primary-400">Más información</span>',
            links: [
              { nombre: "¿Quieres invertir?", url: "https://outlook.office365.com/book/SEGOInversores@segofinance.com/" },
              { nombre: "Contacto por email", url: "mailto:info@segofinance.com" }
            ]
          }
        ];
        return result;
      default:
        return result;
    }
  }
}

