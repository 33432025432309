import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { RiesgosBlastComponent } from 'src/app/blast/shared/riesgos-blast/riesgos-blast.component';


@Component({
  selector: 'app-footer-blast',
  templateUrl: './footer-blast.component.html',
  styleUrls: ['./footer-blast.component.scss'],
  standalone: true,
  imports:[
    SharedModule,
    RiesgosBlastComponent
  ]
})
export class FooterBlastComponent implements OnInit
{

  tipoFooter: string = '';

  constructor(private _router: Router) { }

  ngOnInit(): void
  {
    this.getFooter();
  }

  private getFooter(): void
  {
    if (this._router.url.includes('confirmacion-password'))
    {
      this.tipoFooter = 'password';
    }
  }

}
