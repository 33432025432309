import { Component } from '@angular/core';
import { SharedModule } from 'src/app/_shared/_shared.module';

@Component({
  selector: 'app-tag-new',
  templateUrl: './tag-new.component.html',
  styleUrls: ['./tag-new.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})

export class TagNewComponent {

}
