<div id="footer-web" class="footer container-fluid mw-100 primary-50">
  <!-- #region segofinance global -->
  <div class="row bg-primary-900 py-4">
    <div class="col-12 py-3">
      <div class="container gx-0">
        <div class="border-left-4 border-primary-100 ps-lg-3">
          <h5 class="primary-50">Grupo SEGOFINANCE</h5>
          <div class="linea-separadora-4 border-primary-100 d-lg-none mb-3"></div>
          <div class="row justify-content-between">
            <div class="col-lg-3">
              <p class="my-1 p2-medium neutral-100">
                +34 91 143 64 64
              </p>
              <p class="my-1 p2-medium neutral-100">
                <a class="neutral-100" href="mailto:info@segofinance.com"
                target="blank">info&#64;segofinance.com</a>
              </p>
              <div class="my-3">
                <a href="https://www.linkedin.com/company/sego-finance" target="_blank"
                  class="me-2"><img class="iconSocial" src="assets/images/linkedin.svg" alt="Linkedin"
                title="Linkedin"></a>
                <a href="https://www.instagram.com/segofinance/" target="_blank" class="me-2"><img
                  class="iconSocial" src="assets/images/instagram.svg" alt="Instagram"
                title="Instagram"></a>
                <a href="https://twitter.com/SegoFinance" target="_blank" class="me-2"><img
                  class="iconSocial" src="assets/images/twitter.svg" alt="Twitter"
                title="Twitter"></a>
                <a href="https://www.facebook.com/segofinance/" target="_blank" class="me-2"><img
                  class="iconSocial" src="assets/images/facebook.svg" alt="Facebook"
                title="Facebook"></a>
              </div>
            </div>

            <div class="col-lg-3">
              <a routerLink="/registro" target="_blank"
                class="d-block p2-medium neutral-100 my-2 mt-lg-0" title="Hazte cliente">Hazte
              cliente</a>
              <a href="javascript:void(0)" (click)="openAvisoSalida(('/quienes-somos'))"
                class="d-block p2-medium neutral-100 my-2" title="Quiénes somos">Quiénes
              somos</a>
              <a href="javascript:void(0)" (click)="openAvisoSalida(('/casos-exito'))"
                class="d-block p2-medium neutral-100 my-2" title="Casos de éxito">Casos de
              éxito</a>
              <a href="javascript:void(0)" (click)="openAvisoSalida(('/preguntas-frecuentes'))"
                class="d-block p2-medium neutral-100 mb-lg-0" title="Preguntas Frecuentes">Preguntas
              Frecuentes</a>
            </div>

            <div class="col-lg-3">
              <a href="javascript:void(0)" (click)="openAvisoSalida(('/legal/segofinance/politica-de-privacidad'))"
                class="d-block p2-medium neutral-100 my-2  mt-lg-0"
              title="Política de privacidad">Política de privacidad</a>
              <a href="javascript:void(0)" (click)="openAvisoSalida(('/legal/segofinance/politica-de-cookies'))"
                class="d-block p2-medium neutral-100 my-2"
              title="Política de cookies">Política de cookies</a>
              <a href="javascript:void(0)" (click)="openAvisoSalida(('/legal/segofinance/terminos-y-condiciones'))"
                class="d-block p2-medium neutral-100 my-2"
              title="Términos y condiciones">Términos y condiciones</a>
              <a href="https://blog.segofinance.com/" target="_blank" title="Blog SEGOFINANCE"
              class="d-block p2-medium neutral-100 mb-3 mb-lg-0">Blog SEGOFINANCE</a>
            </div>

            <div class="col-lg-3">
              <h6 class="mb-1 h6-semibold primary-50">
                Ubicación:
              </h6>
              <p class="p2-medium neutral-100 mb-2 ">
                C/Ríos Rosas, 47, 2ºB, 28003 Madrid
              </p>

              <h6 class="mb-1 h6-semibold primary-50">
                Atención al cliente:
              </h6>
              @if (isHorarioVerano) {
                <p class="mb-1 p2-medium neutral-100">
                  L - V: 9:00 a 15:00 h.
                </p>
              }
              @if (!isHorarioVerano) {
                <p class="mb-1 p2-medium neutral-100">
                  L - J: 9:00 a 18:00 h.
                </p>
                <p class="mb-1 p2-medium neutral-100">
                  V: 9:00 a 15:00 h.
                </p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- #endregion segofinance global -->

  <div class="row bg-primary-800">
    <div class="col-12">
      <div class="container mt-4">
        <div class="row justify-content-between">
          @if (template === 'global' || template === 'cnmv') {
            <div
              class="{{template === 'global' ? 'col-lg-2 gx-0 gx-lg-3 mt-3 mt-lg-0' : 'col-lg-3 gx-0 gx-lg-3 mt-3 mt-lg-0'}}">
              <h5 class="primary-50">Venture</h5>
              <div class="linea-separadora-4 border-secondary-venture-500"></div>
              <div class="mt-3">
                <a href="{{ventureDomainUrl}}/oportunidades-inversion" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Oportunidades de inversión">Oportunidades de inversión
                </a>
                <a href="mailto:silvia.morgano@segofinance.com?subject=BuscoFinanciacion"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="¿Buscas financiación?">¿Buscas financiación?
                </a>
                <a routerLink="/legal/startups/informacion-basica" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Información básica para el cliente">Información básica para el cliente
                </a>
                <a routerLink="/legal/startups/condiciones-generales" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Condiciones generales">Condiciones generales
                </a>
                <a routerLink="/legal/startups/reglamento-interno-conducta" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Reglamento interno de conducta">Reglamento interno de conducta
                </a>
                <a routerLink="/legal/startups/reglamento-defensa-cliente" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Reglamento defensa del cliente">Reglamento defensa del cliente
                </a>
                <a routerLink="/legal/startups/politica-de-privacidad" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Política de privacidad">Política de privacidad
                </a>
                <a href="javascript: void(0)" (click)="openTarifasVenture()"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Tarifas y comisiones">Tarifas y comisiones
                </a>
                <a routerLink="/legal/startups/informacion-financiera" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Información financiera">Información financiera
                </a>
                <a href="https://26095004.fs1.hubspotusercontent-eu1.net/hubfs/26095004/Legal%20documents/Mangopay-terms-payment-services_ES_11_2024.pdf" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Términos y condiciones Mangopay">
                  <div class="d-inline-block">
                    <span>Términos y condiciones Mangopay</span>
                    <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                  </div>
                </a>
              </div>
            </div>
          }

          @if (template === 'global' || template === 'cnmv') {
            <div
              class="{{template === 'global' ? 'col-lg-2 gx-0 gx-lg-3 mt-3 mt-lg-0' : 'col-lg-3 gx-0 gx-lg-3 mt-3 mt-lg-0'}}">
              <h5 class="primary-50">Real Estate</h5>
              <div class="linea-separadora-4 border-secondary-estate-500"></div>
              <div class="mt-3">
                <a href="{{realestateDomainUrl}}/oportunidades-inversion" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Oportunidades de inversión">Oportunidades de
                  inversión
                </a>
                <a href="mailto:silvia.morgano@segofinance.com?subject=BuscoFinanciacion"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="¿Buscas financiación?">¿Buscas financiación?
                </a>
                <a routerLink="/legal/realestate/informacion-basica" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Información básica para el cliente">Información
                  básica para el cliente
                </a>
                <a routerLink="/legal/realestate/condiciones-generales" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Condiciones generales">Condiciones generales
                </a>
                <a routerLink="/legal/realestate/reglamento-interno-conducta" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Reglamento interno de conducta">Reglamento interno de
                  conducta
                </a>
                <a routerLink="/legal/realestate/reglamento-defensa-cliente" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Reglamento defensa del cliente">Reglamento defensa del cliente
                </a>
                <a routerLink="/legal/realestate/politica-de-privacidad" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Política de privacidad">Política de privacidad
                </a>
                <a href="javascript: void(0)" (click)="openTarifasRealEstate()"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Tarifas y comisiones">Tarifas y comisiones
                </a>
                <a routerLink="/legal/realestate/informacion-financiera" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Información financiera">Información financiera
                </a>
                <a href="https://26095004.fs1.hubspotusercontent-eu1.net/hubfs/26095004/Legal%20documents/Mangopay-terms-payment-services_ES_11_2024.pdf" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Términos y condiciones Mangopay">
                  <div class="d-inline-block">
                    <span>Términos y condiciones Mangopay</span>
                    <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                  </div>
                </a>
              </div>
            </div>
          }

          @if (template === 'global' || template === 'cnmv') {
            <div
              class="{{template === 'global' ? 'col-lg-2 gx-0 gx-lg-3 mt-3 mt-lg-0' : 'col-lg-3 gx-0 gx-lg-3 mt-3 mt-lg-0'}}">
              <h5 class="primary-50">Creative</h5>
              <div class="linea-separadora-4 border-secondary-creative-500"></div>
              <div class="mt-3">
                <a href="{{creativeDomainUrl}}/audiovisuales" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Oportunidades de inversión">Oportunidades de inversión
                </a>
                <a href="{{creativeDomainUrl}}/buscas-financiacion" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="¿Buscas financiación?">¿Buscas financiación?
                </a>
                <a routerLink="/legal/creative/informacion-basica" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Información básica para el cliente">Información básica para el cliente
                </a>
                <a routerLink="/legal/creative/condiciones-generales" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Condiciones generales">Condiciones generales
                </a>
                <a routerLink="/legal/creative/reglamento-interno-conducta" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Reglamento interno de conducta">Reglamento interno de conducta
                </a>
                <a routerLink="/legal/creative/reglamento-defensa-cliente" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Reglamento defensa del cliente">Reglamento defensa del cliente
                </a>
                <a routerLink="/legal/creative/politica-de-privacidad" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Política de privacidad">Política de privacidad
                </a>
                <a href="javascript: void(0)" (click)="openTarifasCreative()"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Tarifas y comisiones">Tarifas y comisiones
                </a>
                <a routerLink="/legal/creative/informacion-financiera" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Información financiera">Información financiera
                </a>
                <a href="https://26095004.fs1.hubspotusercontent-eu1.net/hubfs/26095004/Legal%20documents/Mangopay-terms-payment-services_ES_11_2024.pdf" target="_blank"
                  class="d-block neutral-100 p3-regular mb-2"
                  title="Términos y condiciones Mangopay">
                  <div class="d-inline-block">
                    <span>Términos y condiciones Mangopay</span>
                    <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                  </div>
                </a>
              </div>
            </div>
          }

          @if (template === 'global' || template === 'no-cnmv') {
            <div
              class="{{template === 'global' ? 'col-lg-2 gx-0 gx-lg-3 mt-3 mt-lg-0' : 'col-lg-7 gx-0 gx-lg-3 mt-3 mt-lg-0'}}">
              <h5 class="primary-50">Factoring</h5>
              <div class="linea-separadora-4 border-secondary-factoring-500"></div>
              <div class="mt-3">
                @if (template === 'no-cnmv') {
                  <div class="row">
                    <div class="col-lg-4">
                      <a href="{{factoringDomainUrl}}/oportunidades-inversion" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Oportunidades de inversión">Oportunidades de inversión
                      </a>
                      <a href="mailto:silvia.morgano@segofinance.com?subject=BuscoFinanciacion"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="¿Buscas financiación?">¿Buscas
                        financiación?
                      </a>
                      <a href="javascript: void(0)" (click)="openTarifasFactoring()"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Tarifas y comisiones">Tarifas y
                        comisiones
                      </a>
                      <a routerLink="/legal/factoring/informacion-basica-cliente" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2 mt-2"
                        title="Información básica para el cliente">Información básica para el cliente
                      </a>
                      <a routerLink="/legal/factoring/terminos-y-condiciones" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Términos y condiciones de uso">Términos
                        y condiciones de uso
                      </a>
                    </div>
                    <div class="col-lg-4">
                      <a routerLink="/legal/factoring/reglamento-interno-conducta" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Reglamento interno de conducta">Reglamento interno de
                        conducta
                      </a>
                      <a routerLink="/legal/factoring/reglamento-defensa-cliente" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Reglamento defensa del cliente">Reglamento defensa del cliente
                      </a>
                      <a routerLink="legal/factoring/politica-de-privacidad" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2 mt-2"
                        title="Política de privacidad">Política de
                        privacidad
                      </a>
                      <a href="https://segoblobstorage.blob.core.windows.net/public/shared/factoring/SF_proceso_general.pdf"
                        target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Proceso general">
                        <div class="d-inline-block">
                          <span>Proceso general</span>
                          <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                        </div>
                      </a>
                    </div>
                    <div class="col-lg-4 pe-0">
                      <a href="https://segoblobstorage.blob.core.windows.net/public/shared/factoring/SF_proceso_recobro.pdf"
                        target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Proceso de recobro">
                        <div class="d-inline-block">
                          <span>Proceso de recobro</span>
                          <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                        </div>
                      </a>
                      <a href="https://26095004.fs1.hubspotusercontent-eu1.net/hubfs/26095004/Legal%20documents/Mangopay-terms-payment-services_ES_11_2024.pdf" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Términos y condiciones Mangopay">
                        <div class="d-inline-block">
                          <span>Términos y condiciones Mangopay</span>
                          <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                        </div>
                      </a>
                      <a [href]="panelEmprendedorDomainUrl" target="_blank"
                        title="¿Quieres adelantar tus facturas?"
                        class="d-block neutral-100 p3-regular mb-2">¿Quieres
                        adelantar tus facturas?
                      </a>
                    </div>
                  </div>
                }
                @if (template === 'global') {
                  <a href="{{factoringDomainUrl}}/oportunidades-inversion" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Oportunidades de inversión">Oportunidades de inversión
                  </a>
                  <a href="mailto:silvia.morgano@segofinance.com?subject=BuscoFinanciacion"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="¿Buscas financiación?">¿Buscas
                    financiación?
                  </a>
                  <a href="javascript: void(0)" (click)="openTarifasFactoring()"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Tarifas y comisiones">Tarifas y
                    comisiones
                  </a>
                  <a routerLink="/legal/factoring/informacion-basica-cliente" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Información básica para el cliente">Información básica para el cliente
                  </a>
                  <a routerLink="/legal/factoring/terminos-y-condiciones" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Términos y condiciones de uso">Términos
                    y condiciones de uso
                  </a>
                  <a routerLink="/legal/factoring/reglamento-interno-conducta" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Reglamento interno de conducta">Reglamento interno de
                    conducta
                  </a>
                  <a routerLink="/legal/factoring/reglamento-defensa-cliente" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Reglamento defensa del cliente">Reglamento defensa del cliente
                  </a>
                  <a routerLink="legal/factoring/politica-de-privacidad" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Política de privacidad">Política de
                    privacidad
                  </a>
                  <a href="https://segoblobstorage.blob.core.windows.net/public/shared/factoring/SF_proceso_general.pdf"
                    target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Proceso general">
                    <div class="d-inline-block">
                      <span>Proceso general</span>
                      <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                    </div>
                  </a>
                  <a href="https://segoblobstorage.blob.core.windows.net/public/shared/factoring/SF_proceso_recobro.pdf"
                    target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Proceso de recobro">
                    <div class="d-inline-block">
                      <span>Proceso de recobro</span>
                      <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                    </div>
                  </a>
                  <a href="https://26095004.fs1.hubspotusercontent-eu1.net/hubfs/26095004/Legal%20documents/Mangopay-terms-payment-services_ES_11_2024.pdf" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Términos y condiciones Mangopay">
                    <div class="d-inline-block">
                      <span>Términos y condiciones Mangopay</span>
                      <span class="material-symbols-outlined ms-1 mat-icon-size-12 icon-height">open_in_new</span>
                    </div>
                  </a>
                  <a [href]="panelEmprendedorDomainUrl" target="_blank"
                    title="¿Quieres adelantar tus facturas?"
                    class="d-block neutral-100 p3-regular mb-2">¿Quieres
                    adelantar tus facturas?
                  </a>
                }
              </div>
            </div>
          }

          @if (template === 'global' || template === 'no-cnmv') {
            <div
              class="{{template === 'global' ? 'col-lg-2 gx-0 gx-lg-3 mt-3 mt-lg-0' : 'col-lg-4 gx-0 gx-lg-3 mt-3 mt-lg-0'}}">
              <h5 class="primary-50">Funds</h5>
              <div class="linea-separadora-4 border-secondary-funds-500"></div>
              <div class="mt-3">
                @if (template === 'no-cnmv') {
                  <div class="row">
                    <div class="col-lg-6">
                      <a href="{{fundsDomainUrl}}/cuentas-remuneradas" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Cuenta remunerada">Cuenta remunerada
                      </a>
                      <a href="{{fundsDomainUrl}}/depositos" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Depósitos">Depósitos
                      </a>
                      <a href="{{fundsDomainUrl}}/indexadas" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2 mt-2"
                        title="Carteras Indexadas">Carteras Indexadas
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="{{fundsDomainUrl}}/tematicas" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Carteras Temáticas">Carteras Temáticas
                      </a>
                      <a routerLink="/legal/funds/politica-de-privacidad/" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Política de privacidad">Política de
                        privacidad
                      </a>
                      <a routerLink="/legal/funds/terminos-y-condiciones/" target="_blank"
                        class="d-block neutral-100 p3-regular mb-2"
                        title="Términos y condiciones">Términos y
                        condiciones
                      </a>
                    </div>
                  </div>
                }
                @if (template === 'global') {
                  <a href="{{fundsDomainUrl}}/cuentas-remuneradas" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Cuenta remunerada">Cuenta remunerada
                  </a>
                  <a href="{{fundsDomainUrl}}/depositos" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Depósitos">Depósitos
                  </a>
                  <a href="{{fundsDomainUrl}}/indexadas" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Carteras Indexadas">Carteras Indexadas
                  </a>
                  <a href="{{fundsDomainUrl}}/tematicas" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Carteras Temáticas">Carteras Temáticas
                  </a>
                  <a routerLink="/legal/funds/politica-de-privacidad/" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Política de privacidad">Política de
                    privacidad
                  </a>
                  <a routerLink="/legal/funds/terminos-y-condiciones/" target="_blank"
                    class="d-block neutral-100 p3-regular mb-2"
                    title="Términos y condiciones">Términos y
                    condiciones
                  </a>
                }
              </div>
            </div>
          }
        </div>
      </div>

      <!-- Botón de ajustes de cookies de CookiePro (inicio) -->
      <div class="container mt-4 gx-0">
        <div class="row">
          <div class="col-12">
            <button id="ot-sdk-btn" class="ot-sdk-show-settings py-2 px-4 border-2 p2-bold"
              title="Configuración cookies">Configuración cookies
            </button>
          </div>
        </div>
      </div>
      <!-- Botón de ajustes de cookies de CookiePro (fin) -->

    </div>
    @if (footerPie) {
      <div id="legal" class="col-12 gx-0">
        <div class="border-2 border-end-0 border-start-0 border-primary-400 mt-4">
          <div class="px-4 d-none d-lg-block ">
            <div class="row">
              @if (template !== 'no-cnmv') {
                <div
                  class="{{template === 'cnmv' ? 'col-11 px-4 py-4 text-lg-center': 'socios col-5 p-4'}}">
                  <p class="p3-regular primary-100 mx-1"
                    [innerHTML]="footerPie.sociosinversores">
                  </p>
                </div>
              }
              @if ((template === 'global' || template === 'no-cnmv')) {
                <div
                  class="socios {{template === 'global' ? 'col-4' : 'col-6'}} p-4">
                  <p class="p3-regular primary-100" [innerHTML]="footerPie.factoring"></p>
                </div>
              }
              @if ((template === 'global' || template === 'no-cnmv')) {
                <div
                  class="{{template === 'global' ? 'col-3' : 'col-6'}} p-4">
                  <p class="p3-regular primary-100" [innerHTML]="footerPie.funds"></p>
                </div>
              }
            </div>
          </div>
          <div class="d-block d-lg-none">
            @if (template !== 'no-cnmv') {
              <div class="container py-4 px-3 px-sm-0">
                <p class="p3-regular primary-100" [innerHTML]="footerPie.sociosinversores">
                </p>
              </div>
            }
            @if ((template === 'global' || template === 'no-cnmv')) {
              <div class="linea-separadora-2 border-primary-400"></div>
              <div class="container py-4 px-3 px-sm-0">
                <p class="p3-regular primary-100" [innerHTML]="footerPie.factoring"></p>
              </div>
              <div class="linea-separadora-2 border-primary-400"></div>
              <div class="container py-4 px-3 px-sm-0">
                <p class="p3-regular primary-100" [innerHTML]="footerPie.funds"></p>
              </div>
            }
          </div>
        </div>
      </div>
    }

    <div class="py-4 text-center">
      <p class="p3-regular primary-100">© {{yearCopyright}} Grupo SEGOFINANCE. Todos los derechos reservados.</p>
    </div>
  </div>
</div>