@if(isAppStable){
@if (tipoCabecera == 'web-sego') {
<app-header></app-header>
}

@if (tipoCabecera == 'blast') {
<app-cabecera-blast></app-cabecera-blast>
}

@if (tipoCabecera === 'plataforma-erronea') {
<app-cabecera-plataforma-erronea></app-cabecera-plataforma-erronea>
}

<div class="d-flex flex-column h-100">
  <div class="app-content" [ngClass]="{
          'margin-logged': isLogged && tipoCabecera !== 'mantenimiento' && tipoCabecera !== 'blast' && tipoCabecera != 'my-investor' && tipoCabecera !== 'creative' && tipoCabecera !== 'info-creative' && tipoCabecera !== 'plataforma-erronea', 
          'margin-header': tipoCabecera !== 'mantenimiento' && tipoCabecera !== 'blast' && tipoCabecera != 'my-investor' && tipoCabecera != 'creative' && tipoCabecera !== 'info-creative' && tipoCabecera !== 'plataforma-erronea',
          'margin-header-plataforma': tipoCabecera === 'plataforma-erronea' }">
    <router-outlet></router-outlet>
  </div>

  @if (tipoCabecera !== 'creative' && tipoCabecera !== 'mantenimiento' && tipoCabecera !== 'info-creative' ) {
  <app-btn-home-floating [isContactarHidden]="isContactarHidden"
    (isContactarClosed)="closeContactar()"></app-btn-home-floating>
  }

  @if (tipoCabecera == 'web-sego' || tipoCabecera == 'mantenimiento' || tipoCabecera == 'plataforma-erronea' ||
  tipoCabecera == 'info-creative') {
  <app-footer [isInfoCreative]="tipoCabecera == 'info-creative'? true : false"></app-footer>
  }

  @if (tipoCabecera == 'blast') {
  <div class="mt-auto">
    <app-footer-blast></app-footer-blast>
  </div>
  }

  @if (tipoCabecera == 'my-investor') {
  <div class="mt-auto">
    <div class="container-fluid bg-primary-800 py-4">
      <div class="container d-flex justify-content-center py-5">
        <span class="p3-regular primary-50">SEGO Factoring es el nombre comercial de la sociedad Smart and Small
          SL, filial del Grupo SEGOFINANCE</span>
      </div>
    </div>
  </div>
  }
</div>
}