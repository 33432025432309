import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { SnackBarErrorConfig } from 'src/app/_utils/_material-import/snackbar-config';
import { MangoPayService } from 'src/app/_shared/bloque-wallet-nuevo/_services/mangopay.service';
import { WalletInfo } from 'src/app/_shared/bloque-wallet-nuevo/_models/wallet-info';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saldo-wallet',
  templateUrl: './saldo-wallet.component.html',
  styleUrls: ['./saldo-wallet.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class SaldoWalletComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  walletInfo: WalletInfo;
  private walletSubscription: Subscription;

  constructor(
    private _mangoPayService: MangoPayService,
    private _matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getSaldoWallet();
    this.walletSubscription = this._mangoPayService.wallet$.subscribe((walletInfo: WalletInfo) => {
      this.updateWalletInfo(walletInfo);
    });
  }

  ngOnDestroy(): void {
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
  }

  private getSaldoWallet(): void {
    this.isLoading = true;
    this._mangoPayService.viewWallet().subscribe({
      next: (r) =>
      {
        this.isLoading = false;
        this.walletInfo = r;
      },
      error: (e) => {
        this.isLoading = false;
        this._matSnackBar.open(this._mangoPayService.getServerErrors(e), 'x', new SnackBarErrorConfig());
      }
    });
  }

  private updateWalletInfo(walletInfo: WalletInfo): void {
    this.walletInfo = walletInfo;
  }
}
