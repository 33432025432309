import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PlatformService } from 'src/app/_services/platform.service';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { Seccion } from 'src/app/header/_models/seccion';
import { Subseccion } from 'src/app/header/_models/subseccion';
import { TagNewComponent } from 'src/app/header/utils/tag-new/tag-new.component';

@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule,
    TagNewComponent
  ]
})

export class MenuDesktopComponent implements OnInit {

  @Input() plataforma: string;
  @Input() seccion: Seccion;
  @Input() subsecciones: Subseccion[];
  @Output() closeMenu = new EventEmitter<boolean>();
  routeActive: string;
  constructor(private _router: Router, private _platformService: PlatformService) { }

  ngOnInit(): void {
    this.routeActive = this._router.url;
  }

  closeMenuDesktop(): void{
    //Asegura cerrar el menu al clickar en cualquier seccion
    this.closeMenu.emit(true);
  }

  openPopupCnmv(plataformaDestino: string, url: string){
    this.closeMenuDesktop();
    if(this.plataforma === plataformaDestino)
    {
      this._router.navigateByUrl(url);
    }
    else if ((this.plataforma === 'venture' && plataformaDestino === 'realestate') || (this.plataforma === 'realestate' && plataformaDestino === 'venture')) 
    {
      window.open(url, '_blank');
    } 
    else {
      this._platformService.openAvisoSalidaCnmv(this.plataforma, plataformaDestino, url);
    }
  }
}
