<div id="menu-desktop" class="fadeinMenu">
    <div class="bg-primary-50 border-radius-4 shadow-8 d-flex align-items-center plataforma-{{plataforma}}" [ngClass]="{
            'subseccion-financiacion-participativa' : seccion.id === 'financiacion-participativa', 
            'subseccion-otros-productos': seccion.id === 'otros-productos',
            'subseccion-producto': seccion.id === 'productos',
            'subseccion-otros-productos-funds-factoring': seccion.id === 'otros-productos-funds-factoring'
            }">

    <!-- Lineas de inversión (SEGOFINANCE) -->
    @if (plataforma === 'segofinance') {
      @if (seccion.id === 'financiacion-participativa' || seccion.id === 'otros-productos') {
        <div
          class="container">
          <div class="row">
            @for (subseccion of subsecciones; track subseccion; let isLastSubseccion = $last) {
              <div class="col">
                <div class="row">
                  <div class="col-12 gx-0">
                    <a href="{{subseccion.route}}"
                      target="{{subseccion.id === 'venture' || subseccion.id === 'realestate' || subseccion.id === 'creative' ? '_blank' : '_self'}}"
                      [routerLinkActive]="'link-active'" class="link-producto"
                      title="SEGO {{subseccion.id | titlecase}}" (click)="closeMenuDesktop()">
                      <div
                        class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start p-4">
                        <div class="pe-3">
                          <div
                            class="icon-container d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                            <span
                            class="material-symbols-outlined mat-icon-size-24 primary-50">{{subseccion.icon}}</span>
                          </div>
                        </div>
                        <div class="d-block">
                          <div class="tabs mb-0" [innerHTML]="subseccion.nombre">
                          </div>
                          <span class="p3-medium" [innerHTML]="subseccion.subtitulo"></span>
                        </div>
                      </div>
                    </a>
                    @if (!isLastSubseccion) {
                      <div class="col-12 gx-0">
                        <div class="linea-separadora-1 border-primary-100"></div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
    }

    <!-- Lineas de inversión (Venture, RealEstate, Creative) -->
    @if (plataforma === 'venture' || plataforma === 'realestate' || plataforma === 'creative') {
      @if (seccion.id === 'financiacion-participativa') {
        <div class="container">
          <div class="row">
            @for (subseccion of subsecciones; track subseccion) {
              <div class="col">
                <div class="row">
                  <div class="col-12 gx-0">
                    @if (subseccion.linkType === 'routerLink') {
                      <a routerLink="{{subseccion.route}}"
                        [routerLinkActive]="'link-active'" class="link-producto"
                        title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                        (click)="closeMenuDesktop()">
                        <div
                          class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start p-4">
                          <div class="pe-3">
                            <div
                              class="icon-container d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                              <span
                              class="material-symbols-outlined mat-icon-size-24 primary-50">{{subseccion.icon}}</span>
                            </div>
                          </div>
                          <div class="d-block">
                            <div class="tabs mb-0" [innerHTML]="subseccion.nombre">
                            </div>
                            <span class="p3-medium" [innerHTML]="'Tu panel del inversor'"></span>
                          </div>
                        </div>
                      </a>
                    }
                    @if (subseccion.linkType === 'href') {
                      <a href="{{subseccion.route}}"
                        [routerLinkActive]="'link-active'" class="link-producto"
                        title="SEGO {{subseccion.id | titlecase}}: Tu panel del inversor"
                        (click)="closeMenuDesktop()">
                        <div
                          class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start p-4">
                          <div class="pe-3">
                            <div
                              class="icon-container d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                              <span
                              class="material-symbols-outlined mat-icon-size-24 primary-50">{{subseccion.icon}}</span>
                            </div>
                          </div>
                          <div class="d-block">
                            <div class="tabs mb-0" [innerHTML]="subseccion.nombre">
                            </div>
                            <span class="p3-medium" [innerHTML]="'Tu panel del inversor'"></span>
                          </div>
                        </div>
                      </a>
                    }
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 gx-0">
                    <div class="linea-separadora-1 border-primary-100"></div>
                  </div>
                </div>
                <div class="row p-4 pt-3">
                  @for (link of subseccion.links; track link; let isLastLink = $last) {
                    <div class="col-12 text-start mb-2"
                      [ngClass]="{'mb-1' : !isLastLink}">
                      @if (link.route && subseccion.linkType === 'routerLink') {
                        <a
                          routerLink="{{link.route}}" [routerLinkActive]="'link-active'"
                          title="{{link.nombre}}" class="all-menu primary-800 h-100"
                          (click)="closeMenuDesktop()">
                          <div class="d-flex align-items-center">
                            {{link.nombre}}
                            @if (link.isNew) {
                              <div class="ms-2">
                                <app-tag-new></app-tag-new>
                              </div>
                            }
                          </div>
                        </a>
                      }
                      @if (link.route && subseccion.linkType === 'href') {
                        <a href="{{link.route}}"
                          title="{{link.nombre}}" class="all-menu primary-800 h-100"
                          (click)="closeMenuDesktop()">
                          <div class="d-flex align-items-center">
                            {{link.nombre}}
                            @if (link.isNew) {
                              <div class="ms-2">
                                <app-tag-new></app-tag-new>
                              </div>
                            }
                          </div>
                        </a>
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
    }

    <!-- Lineas de inversión (Factoring, Funds) -->
    @if (plataforma === 'factoring' || plataforma === 'funds') {
      @if (seccion.id === 'productos') {
        <div class="container">
          <div class="row">
            @for (subseccion of subsecciones; track subseccion) {
              <div class="col">
                <div class="row">
                  <div class="col-12 gx-0">
                    <a routerLink="{{subseccion.route}}" [routerLinkActive]="'link-active'"
                      class="link-producto" title="SEGO {{subseccion.id | titlecase}}:"
                      (click)="closeMenuDesktop()">
                      <div
                        class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start p-4">
                        <div class="pe-3">
                          <div
                            class="icon-container d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                            <span
                            class="material-symbols-outlined mat-icon-size-24 primary-50">{{subseccion.icon}}</span>
                          </div>
                        </div>
                        <div class="d-block">
                          <div class="tabs mb-0" [innerHTML]="subseccion.nombre">
                          </div>
                          <span class="p3-medium" [innerHTML]="'Tu panel del inversor'"></span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 gx-0">
                    <div class="linea-separadora-1 border-primary-100"></div>
                  </div>
                </div>
                <div class="row p-4 pt-3">
                  @for (link of subseccion.links; track link; let isLastLink = $last) {
                    <div class="col-12 text-start mb-2"
                      [ngClass]="{'mb-1' : !isLastLink}">
                      @if (link.route && !link.url) {
                        <a routerLink="{{link.route}}"
                          [routerLinkActive]="'link-active'" title="{{link.nombre}}"
                          class="all-menu primary-800 h-100" (click)="closeMenuDesktop()">
                          <div class="d-flex align-items-center">
                            {{link.nombre}}
                            @if (link.isNew) {
                              <div class="ms-2">
                                <app-tag-new></app-tag-new>
                              </div>
                            }
                          </div>
                        </a>
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
      @if (seccion.id === 'otros-productos-funds-factoring') {
        <div class="container">
          <div class="row">
            @for (subseccion of subsecciones; track subseccion; let isLastLink = $last) {
              <div class="col-12">
                <div class="row">
                  @if (subseccion.id === 'funds' || subseccion.id === 'factoring') {
                    <div class="col-12 gx-0">
                      <a href="javascript:void(0)" (click)="openPopupCnmv(subseccion.id, subseccion.route)"
                        [routerLinkActive]="'link-active'" class="link-producto"
                        title="SEGO {{subseccion.id | titlecase}}" (click)="closeMenuDesktop()">
                        <div
                          class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start p-4">
                          <div class="pe-2">
                            <div
                              class="icon-container d-flex justify-content-center align-items-center {{subseccion.lineaColor}}">
                              <span
                              class="material-symbols-outlined mat-icon-size-28 primary-50">{{subseccion.icon}}</span>
                            </div>
                          </div>
                          <div class="d-block">
                            <div class="tabs mb-0" [innerHTML]="subseccion.nombre">
                            </div>
                            <span class="p3-medium" [innerHTML]="subseccion.subtitulo"></span>
                          </div>
                        </div>
                      </a>
                    </div>
                  }
                  @if (subseccion.id === 'segofinance') {
                    <div class="col-12 gx-0">
                      <a href="javascript:void(0)"
                        (click)="openPopupCnmv(subseccion.id, subseccion.route); closeMenuDesktop()"
                        [routerLinkActive]="'link-active'" class="link-producto" title="Grupo SEGOFINANCE">
                        <div
                          class="{{subseccion.id}} d-flex align-items-center justify-content-start text-start p-4">
                          <img src="./assets/images/SegoFinance_HN.svg" class="sego-logo"
                            alt="Grupo SEGOFINANCE" title="Grupo SEGOFINANCE">
                          </div>
                        </a>
                      </div>
                    }
                  </div>
                  @if (!isLastLink) {
                    <div class="row">
                      <div class="col-12 gx-0">
                        <div class="linea-separadora-1 border-primary-100"></div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      }

      <!-- Otras secciones -->
      @if (seccion.id !== 'financiacion-participativa' && seccion.id !== 'otros-productos' && seccion.id !== 'productos' && seccion.id !== 'otros-productos-funds-factoring') {
        <div
          class="subseccion-general">
          @for (subseccion of subsecciones; track subseccion) {
            <div>
              <div class="subseccion-title p-4">
                <div class="d-flex align-items-center">
                  <span class="material-symbols-outlined primary-400">{{subseccion.icon}}</span>
                  <div [innerHTML]="subseccion.nombre" class="ms-2 primary-800 all-menu mb-0"></div>
                </div>
              </div>
              <div class="subseccion-links px-4 mb-2">
                @for (link of subseccion.links; track link; let isLastLink = $last) {
                  <div
                    class="text-start {{isLastLink ? 'pb-4': 'pb-2'}}">
                    @if (link.route && !link.url) {
                      <a routerLink="{{link.route}}"
                        [routerLinkActive]="'text-decoration-underline'" title="{{link.nombre}}"
                        class="all-menu primary-800 h-100" (click)="closeMenuDesktop()">
                        {{link.nombre}}
                      </a>
                    }
                    @if (link.url && !link.route) {
                      <a href="{{link.url}}" target="_blank" title="{{link.nombre}}"
                        class="all-menu primary-800 h-100" (click)="closeMenuDesktop()">
                        {{link.nombre}}
                      </a>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>