import { APP_ID, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { httpInterceptorProviders } from 'src/app/_utils/httpInterceptorProviders';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideClarity } from 'ngx-clarity';
import { environment } from 'src/environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from 'src/app/_utils/_material-import/customMatPaginatorIntl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    httpInterceptorProviders,
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    provideRouter(routes),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi()
    ),
    importProvidersFrom(
      BrowserAnimationsModule,
      BrowserModule),
    provideClarity({ enabled: environment.production, projectId: 'fflv5eayrp' }),
  ]
};
