@if (isLoading) {
  <div class="d-flex align-items-center">
    <app-is-loading [color]="'segofinance'" [size]="16" [stroke]="2"></app-is-loading> <span class="t2-m ms-2">€</span>
  </div>
}

@if (!isLoading && !walletInfo?.importe) {
  0,00 €
}

@if (!isLoading && walletInfo?.importe) {
  {{walletInfo.importe | euroCurrency}}
}